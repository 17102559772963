import { z } from '@hono/zod-openapi'

export const WorkspaceStatisticsOutput = z.object({
  changelogs: z.number().openapi({
    title: 'Workspace changelogs count',
    example: 1,
  }),
  commits: z.number().openapi({
    title: 'Workspace commits count',
    example: 123,
  }),
  versions: z.number().openapi({
    title: 'Workspace version count',
    example: 3,
  }),
})
