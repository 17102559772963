import { changelogByIdOptions } from '@boring.tools/hooks'
import {
  Button,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@boring.tools/ui'
import { useSuspenseQuery } from '@tanstack/react-query'
import { Link, createFileRoute, redirect } from '@tanstack/react-router'
import { FileStackIcon, PencilIcon } from 'lucide-react'
import { ChangelogDelete } from '../../components/Changelog/Delete'
import { ChangelogVersionList } from '../../components/Changelog/VersionList'
import { DashboardCharts } from '../../components/Dashboard/Charts'
import { Layout } from '../../components/Layout'
import { PageWrapper } from '../../components/PageWrapper'

const Component = () => {
  const { id } = Route.useParams()
  const { data } = useSuspenseQuery(changelogByIdOptions(id))

  return (
    <Layout>
      <PageWrapper
        breadcrumbs={[
          {
            name: 'Changelog',
            to: '/changelog',
          },
          { name: data?.title ?? '', to: `/changelog/${data?.id}` },
        ]}
      >
        <div className="flex flex-col gap-5">
          {data && (
            <div>
              <div className="flex justify-between items-center">
                <div className="flex gap-3 items-center">
                  <FileStackIcon
                    strokeWidth={1.5}
                    className="w-8 h-8 text-muted-foreground"
                  />
                  <div>
                    <h1 className="text-2xl">{data.title}</h1>

                    <p className="text-muted-foreground mt-2">
                      {data.description}
                    </p>
                  </div>
                </div>

                <div className="flex items-center gap-3">
                  {/* <Tooltip>
                <TooltipTrigger asChild>
                  <Button variant={'ghost'}>
                    <TerminalSquareIcon strokeWidth={1.5} />
                  </Button>
                </TooltipTrigger>
                <TooltipContent>
                  <p>CLI</p>
                </TooltipContent>
              </Tooltip>

               */}

                  <Tooltip>
                    <TooltipTrigger asChild>
                      <Link to={'/changelog/$id/edit'} params={{ id }}>
                        <Button variant={'ghost'}>
                          <PencilIcon strokeWidth={1.5} />
                        </Button>
                      </Link>
                    </TooltipTrigger>
                    <TooltipContent>
                      <p>Edit</p>
                    </TooltipContent>
                  </Tooltip>

                  <ChangelogDelete id={id} />
                </div>
              </div>
              <div className="mt-5 flex gap-10 flex-col">
                <DashboardCharts changelogId={id} />
                <ChangelogVersionList />
                {/* <ChangelogCommitList id={id} /> */}
              </div>
            </div>
          )}
        </div>
      </PageWrapper>
    </Layout>
  )
}

export const Route = createFileRoute('/changelog/$id')({
  component: Component,
  beforeLoad: ({ context, location }) => {
    if (!context.auth.user) {
      throw redirect({
        to: '/authentication',
        search: {
          redirect: location.href,
        },
      })
    }
  },
  loader: ({ context, params }) => {
    return context.queryClient?.ensureQueryData(changelogByIdOptions(params.id))
  },
})
