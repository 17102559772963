import { useWorkspaceUpdate } from '@boring.tools/hooks'
import { type WorkspaceOutput, WorkspacePutInput } from '@boring.tools/schema'
import {
  Button,
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  Textarea,
  toast,
} from '@boring.tools/ui'
import type { z } from '@hono/zod-openapi'
import { zodResolver } from '@hookform/resolvers/zod'
import { useRouteContext } from '@tanstack/react-router'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'

export const WorkspaceSettingsGeneral = ({
  data,
}: { data: z.infer<typeof WorkspaceOutput> }) => {
  const { auth } = useRouteContext({ from: '/workspace/' })
  const update = useWorkspaceUpdate()
  const form = useForm<z.infer<typeof WorkspacePutInput>>({
    resolver: zodResolver(WorkspacePutInput),
    defaultValues: data,
  })

  useEffect(() => {
    form.reset(data)
  }, [data, form])

  const onSubmit = (values: z.infer<typeof WorkspacePutInput>) => {
    if (!auth.user?.currentWorkspaceId) {
      return
    }

    update.mutate(
      { id: auth.user?.currentWorkspaceId, payload: values },
      {
        onSuccess: () => {
          toast.success('Workspace updated')
        },
      },
    )
  }
  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
        <FormField
          control={form.control}
          name="name"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Name</FormLabel>
              <FormControl>
                <Input placeholder="" {...field} />
              </FormControl>
              <FormDescription>Name of your workspace</FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="description"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Description</FormLabel>
              <FormControl>
                <Textarea placeholder="" {...field} />
              </FormControl>
              <FormDescription>
                A short description about your workspace
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />

        <Button type="submit">Update</Button>
      </form>
    </Form>
  )
}
