import { useChangelogVersionRemove } from '@boring.tools/hooks'
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
  Button,
} from '@boring.tools/ui'
import { useNavigate } from '@tanstack/react-router'
import { useState } from 'react'

export const ChangelogVersionDelete = ({
  id,
  versionId,
}: { id: string; versionId: string }) => {
  const remove = useChangelogVersionRemove()
  const navigate = useNavigate()
  const [isOpen, setIsOpen] = useState(false)

  const removeChangelogVersion = () => {
    remove.mutate(
      { id: versionId },
      {
        onSuccess: () => {
          setIsOpen(false)
          navigate({ to: '/changelog/$id', params: { id } })
        },
      },
    )
  }
  return (
    <AlertDialog open={isOpen}>
      <AlertDialogTrigger asChild>
        <Button variant={'destructive'} onClick={() => setIsOpen(true)}>
          Remove
        </Button>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
          <AlertDialogDescription>
            This action cannot be undone. This will permanently delete your
            version and remove your data from our servers.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel onClick={() => setIsOpen(false)}>
            Cancel
          </AlertDialogCancel>
          <AlertDialogAction asChild>
            <Button onClick={removeChangelogVersion} variant={'destructive'}>
              Remove
            </Button>
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}
