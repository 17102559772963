import { CheckIcon, ChevronsUpDown, PlusIcon } from 'lucide-react'

import { workspaceGetAllOptions } from '@boring.tools/hooks'
import { useAuth, useUserUpdate } from '@boring.tools/hooks'
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuPortal,
  DropdownMenuSeparator,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
} from '@boring.tools/ui'
import { useSuspenseQuery } from '@tanstack/react-query'
import { Link } from '@tanstack/react-router'

export function SidebarWorkspace() {
  const { user } = useAuth()
  const { data } = useSuspenseQuery(workspaceGetAllOptions())
  const userUpdate = useUserUpdate()

  const currentWorkspace = data?.find(
    (workspace) => workspace.id === user?.currentWorkspaceId,
  )

  return (
    <SidebarMenu>
      <SidebarMenuItem>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <SidebarMenuButton
              size="lg"
              className="data-[state=open]:bg-sidebar-accent data-[state=open]:text-sidebar-accent-foreground"
            >
              <Avatar className="h-8 w-8 rounded-lg">
                <AvatarImage
                  src={currentWorkspace?.logo ?? ''}
                  alt={currentWorkspace?.name ?? 'Avatar'}
                />
                <AvatarFallback className="rounded-lg">
                  {currentWorkspace?.name?.substring(0, 2)}
                </AvatarFallback>
              </Avatar>
              <div className="grid flex-1 text-left text-sm leading-tight">
                <span className="truncate font-semibold">
                  {currentWorkspace?.name}
                </span>
                <span className="truncate text-xs">
                  {currentWorkspace?.description}
                </span>
              </div>
              <ChevronsUpDown className="ml-auto size-4" />
            </SidebarMenuButton>
          </DropdownMenuTrigger>
          <DropdownMenuContent
            className="w-[--radix-dropdown-menu-trigger-width] min-w-56 rounded-lg"
            side={'bottom'}
            align="end"
            sideOffset={4}
          >
            <DropdownMenuGroup>
              <DropdownMenuItem asChild>
                <Link to="/workspace/member">Members</Link>
              </DropdownMenuItem>
              <DropdownMenuItem asChild>
                <Link to="/workspace/billing">Billing</Link>
              </DropdownMenuItem>
              <DropdownMenuItem asChild>
                <Link to="/workspace">Settings</Link>
              </DropdownMenuItem>
            </DropdownMenuGroup>

            <DropdownMenuSeparator />

            <DropdownMenuSub>
              <DropdownMenuSubTrigger>
                <span>Switch workspace</span>
              </DropdownMenuSubTrigger>
              <DropdownMenuPortal>
                <DropdownMenuSubContent>
                  {data?.map((workspace) => (
                    <DropdownMenuItem
                      key={workspace.id}
                      disabled={workspace.id === user?.currentWorkspaceId}
                      onClick={() =>
                        userUpdate.mutate({ currentWorkspaceId: workspace.id })
                      }
                    >
                      <span className="w-full">{workspace.name}</span>
                      {workspace.id === user?.currentWorkspaceId && (
                        <CheckIcon className="w-3 h-3" />
                      )}
                    </DropdownMenuItem>
                  ))}
                  <DropdownMenuSeparator />

                  <DropdownMenuItem asChild>
                    <Link to="/workspace/create">
                      <PlusIcon className="w-4 h-4" />
                      <span className="ml-1 w-full">Create a workspace</span>
                    </Link>
                  </DropdownMenuItem>
                </DropdownMenuSubContent>
              </DropdownMenuPortal>
            </DropdownMenuSub>
          </DropdownMenuContent>
        </DropdownMenu>
      </SidebarMenuItem>
    </SidebarMenu>
  )
}
