import { useBillingSession } from '@boring.tools/hooks'
import {
  Button,
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
  Separator,
  cn,
} from '@boring.tools/ui'
import {
  CreditCardIcon,
  LoaderCircleIcon,
  MinusIcon,
  PlusIcon,
} from 'lucide-react'
import { useState } from 'react'
import { Euro } from '../../utils/numbers'

const plans = [
  {
    name: 'Starter',
    description: 'For solo devs and small teams.',
    monthlyPrice: 1000,
    yearlyPrice: 10000,
    monthlyId: 'price_1QQRAbHfXLrT6x0wDCW5Zu5J',
    yearlyId: 'price_1QQRAbHfXLrT6x0w84WemxWC',
  },
  {
    name: 'Booster',
    description: 'For bigger teams and products.',
    monthlyPrice: 2500,
    yearlyPrice: 25000,
    monthlyId: 'price_1QQy9fHfXLrT6x0wZ63o0ZwX',
    yearlyId: 'price_1QQy9fHfXLrT6x0w4FAKGdHt',
  },
]

// TODO: 3 steps to complete the subscription
// 1. Choose billing period
// 2. Choose plan
// 3. Choose seats
// 4. Sum up the total and checkout button

export const BillingSubscribe = () => {
  const [step, setStep] = useState(0)
  const [interval, setInterval] = useState<string | null>(null)
  const [plan, setPlan] = useState<string | null>(null)
  const [seats, setSeats] = useState(1)

  const session = useBillingSession()

  const onSubmit = () => {
    const getPlan = plans.find((f) => f.name === plan)
    const values = {
      line_items: [
        {
          price:
            interval === 'monthly' ? getPlan?.monthlyId : getPlan?.yearlyId,
          quantity: seats,
          adjustable_quantity: {
            enabled: true,
            minimum: seats,
            maximum: 10,
          },
        },
      ],
    }

    session.mutate(
      {
        ...values,
        line_items: values.line_items.map((item) => ({
          ...item,
          price: item.price ?? '',
        })),
      },
      {
        onSuccess: (data) => {
          window.location.href = data.url
        },
      },
    )
  }

  const decrementSeats = () => {
    if (seats > 1) {
      setSeats(seats - 1)
    }
  }

  const incrementSeats = () => {
    setSeats(seats + 1)
  }

  const chooseInterval = (interval: string) => {
    setInterval(interval)
    setStep(1)
  }

  const choosePlan = (plan: string) => {
    setPlan(plan)
    setStep(2)
  }

  const getSelectedPlan = () => {
    return plans.find((f) => f.name === plan)
  }

  return (
    <div className="w-full flex flex-col items-center mt-20">
      <div className="absolute top-10 left-10 opacity-30">
        <img src="/logo.svg" alt="Subscribe" />
        <span className="tracking-widest text-3xl font-light">
          boring.tools
        </span>
      </div>
      <div className="w-full max-w-screen-md">
        <h1 className="text-4xl">Subscription</h1>
        <p className="text-lg text-muted-foreground max-w-md">
          Please choose one of our plans and the number of members you would
          like to invite.
        </p>

        <div className="flex flex-col my-10 relative">
          <div
            className={cn('relative transition mb-5', {
              'opacity-20 hover:opacity-65 cursor-pointer': step !== 0,
            })}
          >
            <span
              className={cn('text-7xl absolute right-0 text-muted-foreground')}
            >
              01
            </span>
            {/* biome-ignore lint/a11y/useKeyWithClickEvents: <explanation> */}
            <h1 onClick={() => setStep(0)} className="text-2xl">
              Select a billing interval{' '}
              {interval && (
                <span className="uppercase text-base">({interval})</span>
              )}
            </h1>

            {step === 0 && (
              <div className="flex gap-5 items-center mt-5">
                <Button
                  onClick={() => chooseInterval('monthly')}
                  variant={interval === 'monthly' ? 'default' : 'outline'}
                  size={'lg'}
                  className="h-20 w-36"
                >
                  Monthly
                </Button>
                <Button
                  onClick={() => chooseInterval('yearly')}
                  variant={interval === 'yearly' ? 'default' : 'outline'}
                  size={'lg'}
                  className="h-20 w-36"
                >
                  Yearly
                </Button>
              </div>
            )}
          </div>

          <div
            className={cn('relative transition mb-5', {
              'opacity-20 hover:opacity-65 cursor-pointer': step !== 1,
            })}
          >
            <span
              className={cn(
                'text-7xl absolute right-0 text-muted-foreground',
                {},
              )}
            >
              02
            </span>
            {/* biome-ignore lint/a11y/useKeyWithClickEvents: <explanation> */}
            <h1 className="text-2xl" onClick={() => setStep(1)}>
              Choose your plan{' '}
              {plan && <span className="uppercase text-base">({plan})</span>}
            </h1>

            {step === 1 && (
              <div className="flex gap-5 items-center mt-5">
                {step >= 1 &&
                  plans.map((planItem) => (
                    <Card
                      key={planItem.name}
                      className={cn(
                        'w-full hover:border-primary transition cursor-pointer select-none',
                        { 'border-primary': planItem.name === plan },
                      )}
                      onClick={() => choosePlan(planItem.name)}
                    >
                      <CardHeader>
                        <CardTitle>{planItem.name}</CardTitle>
                        <CardDescription>
                          {planItem.description}
                        </CardDescription>
                      </CardHeader>
                      <CardContent>
                        <div className="uppercase flex flex-col">
                          <span className="text-4xl">
                            {Euro.format(
                              (interval === 'monthly'
                                ? planItem.monthlyPrice
                                : planItem.yearlyPrice) / 100,
                            )}
                          </span>
                          <span className="text-sm text-muted-foreground">
                            x {seats} seat
                          </span>
                        </div>
                      </CardContent>
                    </Card>
                  ))}
              </div>
            )}
          </div>

          <div
            className={cn('relative transition mb-5', {
              'opacity-20 hover:opacity-65 cursor-pointer': step !== 2,
            })}
          >
            <span
              className={cn(
                'text-7xl absolute right-0 text-muted-foreground',
                {},
              )}
            >
              03
            </span>
            <h1 className="text-2xl">How many seats?</h1>

            {step === 2 && (
              <div className="flex gap-5 items-center justify-center my-10">
                <Button
                  onClick={decrementSeats}
                  variant={'outline'}
                  size={'lg'}
                  className="h-16 w-16"
                >
                  <MinusIcon className="w-8 h-8 shrink-0" />
                </Button>
                <span className="text-8xl font-thin">{seats}</span>
                <Button
                  onClick={incrementSeats}
                  variant={'default'}
                  size={'lg'}
                  className="h-16 w-16"
                >
                  <PlusIcon className="w-8 h-8 shrink-0" />
                </Button>
              </div>
            )}
          </div>

          {step >= 2 && (
            <div className="flex flex-col items-end">
              <span className={cn('text-2xl text-muted-foreground')}>
                {seats} Seats X{' '}
                {Euro.format(
                  // @ts-ignore
                  (interval === 'monthly'
                    ? getSelectedPlan()?.monthlyPrice
                    : getSelectedPlan()?.yearlyPrice) / 100,
                )}
              </span>
              <Separator className="my-3" />
              <span className="text-4xl">
                {Euro.format(
                  (seats *
                    // @ts-ignore
                    (interval === 'monthly'
                      ? getSelectedPlan()?.monthlyPrice
                      : getSelectedPlan()?.yearlyPrice)) /
                    100,
                )}
              </span>
              <span className="text-lg">{interval}</span>

              <Button
                type="button"
                variant={'default'}
                className="mt-5 gap-2"
                size={'lg'}
                onClick={onSubmit}
                disabled={session.isPending}
              >
                {session.isPending && (
                  <LoaderCircleIcon className="w-6 h-6 animate-spin" />
                )}
                {!session.isPending && (
                  <>
                    <CreditCardIcon className="w-6 h-6" />
                    Checkout
                  </>
                )}
              </Button>

              <small className="text-muted-foreground text-sm mt-4">
                We use Stripe as our payment provider. Therefore, by clicking on
                Checkout you will be redirected to Stripe to complete the
                payment.
              </small>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
