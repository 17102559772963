import type { VersionCreateInput } from '@boring.tools/schema'
import {
  Button,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
} from '@boring.tools/ui'
import type { z } from '@hono/zod-openapi'
import type { UseFormReturn } from 'react-hook-form'
import slug from 'slug'

export const ChangelogVersionFormDetails = ({
  form,
}: { form: UseFormReturn<z.infer<typeof VersionCreateInput>> }) => {
  const generateSlug = () => {
    form.setValue('slug', slug(form.getValues('title')))
  }
  return (
    <span className="flex flex-col">
      <h2 className="text-lg font-bold mb-2">Details</h2>
      <div className="flex flex-col gap-4">
        <FormField
          control={form.control}
          name="title"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Title</FormLabel>
              <FormControl>
                <Input placeholder="Our new feature ..." {...field} autoFocus />
              </FormControl>{' '}
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="version"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Version</FormLabel>
              <FormControl>
                <Input placeholder="v1.0.1" {...field} />
              </FormControl>{' '}
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="slug"
          render={({ field }) => (
            <FormItem>
              <div className="flex justify-between items-center">
                <FormLabel>Slug</FormLabel>
                <Button
                  type="button"
                  variant={'ghost'}
                  size={'xs'}
                  onClick={generateSlug}
                >
                  Generate
                </Button>
              </div>
              <FormControl>
                <Input placeholder="my-version-slug" {...field} />
              </FormControl>{' '}
              <FormMessage />
            </FormItem>
          )}
        />
      </div>
    </span>
  )
}
