import {
  Button,
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
  Sidebar as SidebarComp,
  SidebarContent,
  SidebarFooter,
  SidebarGroup,
  SidebarGroupContent,
  SidebarHeader,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
} from '@boring.tools/ui'
import { Link } from '@tanstack/react-router'
import { HomeIcon } from 'lucide-react'
import { SidebarChangelog } from './Changelog'
import { SidebarPage } from './Page'
import { SidebarProfile } from './Profile'
import { SidebarWorkspace } from './Workspace'

export function Sidebar() {
  return (
    <SidebarComp>
      <SidebarHeader>
        <SidebarWorkspace />
      </SidebarHeader>
      <SidebarContent>
        <SidebarGroup>
          <SidebarGroupContent>
            <SidebarMenu>
              <SidebarMenuItem>
                <SidebarMenuButton asChild tooltip="Changelog">
                  <Link to="/" activeProps={{ className: 'bg-sidebar-accent' }}>
                    <HomeIcon />
                    <span>Dashboard</span>
                  </Link>
                </SidebarMenuButton>
              </SidebarMenuItem>
            </SidebarMenu>
          </SidebarGroupContent>
        </SidebarGroup>

        <SidebarGroup>
          <SidebarChangelog />
        </SidebarGroup>
        <SidebarGroup>
          <SidebarPage />
        </SidebarGroup>
        {/* <SidebarGroup>
          <SidebarFile />
        </SidebarGroup> */}
      </SidebarContent>

      <SidebarFooter>
        {/* <SidebarMenu>
          <SidebarMenuItem>
            <SidebarMenuButton asChild tooltip="Access Tokens">
              <Link to="/cli" activeProps={{ className: 'bg-sidebar-accent' }}>
                <TerminalIcon />
                <span>CLI</span>
              </Link>
            </SidebarMenuButton>
          </SidebarMenuItem>
        </SidebarMenu> */}

        <Card className="shadow-none">
          <form>
            <CardHeader className="p-4 pb-0">
              <CardTitle className="text-sm">Use the CLI</CardTitle>
              <CardDescription>
                Upload your git commits in seconds!
              </CardDescription>
            </CardHeader>
            <CardContent className="grid gap-2.5 p-4">
              <Button size="sm" asChild>
                <Link to="/cli">Download CLI</Link>
              </Button>
            </CardContent>
          </form>
        </Card>
        <SidebarProfile />
      </SidebarFooter>
    </SidebarComp>
  )
}
