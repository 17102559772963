import { z } from '@hono/zod-openapi'

export const CommitStatisticsInput = z.object({
  changelogId: z.string().optional().nullable(),
  from: z.date().or(z.string()).optional(),
  to: z.date().or(z.string()).optional(),
})
export const CommitStatisticsOutput = z.array(
  z.object({
    date: z.date().or(z.string()),
    commits: z.number(),
    hasRelease: z.boolean().default(false),
    types: z
      .array(
        z.object({
          type: z.string(),
          count: z.number(),
        }),
      )
      .optional(),
    scopes: z
      .array(
        z.object({
          scope: z.string(),
          count: z.number(),
        }),
      )
      .optional(),
  }),
)
