import { z } from '@hono/zod-openapi'

export const PagePublicOutput = z.object({
  name: z.string(),
  description: z.string().nullable(),
  logo: z.string().optional().nullable(),
  changelogs: z
    .array(
      z.object({
        title: z.string(),
        description: z.string(),
        versions: z.array(
          z.object({
            content: z.string(),
            version: z.string(),
            title: z.string().nullable(),
            releasedAt: z.date().or(z.string()).nullable(),
          }),
        ),
      }),
    )
    .optional(),
})

export const PagePublicParams = z.object({
  id: z
    .string()
    .uuid()
    .openapi({
      param: {
        name: 'id',
        in: 'path',
      },
      example: 'a5ed5965-0506-44e6-aaec-0465ff9fe092',
    }),
})

export const PageCNameParams = z.object({
  cname: z.string().openapi({
    param: {
      name: 'cname',
      in: 'path',
    },
    example: 'changelog.example.com',
  }),
})
