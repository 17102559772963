import { changelogListOptions } from '@boring.tools/hooks'
import { Card, CardContent, CardHeader, CardTitle } from '@boring.tools/ui'
import { useSuspenseQuery } from '@tanstack/react-query'
import { Link, Outlet, createFileRoute, redirect } from '@tanstack/react-router'
import { PlusCircleIcon } from 'lucide-react'
import { Layout } from '../../components/Layout'
import { PageWrapper } from '../../components/PageWrapper'

const Component = () => {
  const { data } = useSuspenseQuery(changelogListOptions())

  return (
    <Layout>
      <PageWrapper breadcrumbs={[{ name: 'Changelog', to: '/changelog' }]}>
        <div className="flex flex-col gap-5">
          <h1 className="text-2xl">Changelog</h1>

          <div className="flex gap-10 w-full flex-wrap">
            {data.map((changelog) => {
              return (
                <Link
                  to="/changelog/$id"
                  params={{ id: changelog.id }}
                  key={changelog.id}
                >
                  <Card className="max-w-56 min-w-56 w-full h-36 hover:border-emerald-700 transition">
                    <CardHeader className="flex items-center justify-center">
                      <CardTitle>{changelog.title}</CardTitle>
                    </CardHeader>
                    <CardContent className="flex items-center justify-center flex-col">
                      <span>Versions: {changelog.computed?.versionCount}</span>

                      <span>Commits: {changelog.computed?.commitCount}</span>
                    </CardContent>
                  </Card>
                </Link>
              )
            })}

            <Link to="/changelog/create">
              <Card className="max-w-56 min-w-56 w-full h-36 hover:border-emerald-700 transition">
                <CardHeader className="flex items-center justify-center">
                  <CardTitle>New Changelog</CardTitle>
                </CardHeader>
                <CardContent className="flex items-center justify-center">
                  <PlusCircleIcon strokeWidth={1.5} className="w-10 h-10" />
                </CardContent>
              </Card>
            </Link>
          </div>
        </div>
        <Outlet />
      </PageWrapper>
    </Layout>
  )
}

export const Route = createFileRoute('/changelog/')({
  component: Component,
  beforeLoad: ({ context, location }) => {
    if (!context.auth.user) {
      throw redirect({
        to: '/authentication',
        search: {
          redirect: location.href,
        },
      })
    }
  },
  loader: ({ context }) => {
    return context.queryClient?.ensureQueryData(changelogListOptions())
  },
})
