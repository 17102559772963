import { z } from '@hono/zod-openapi'

export const PageCreateInput = z
  .object({
    name: z.string().min(3).openapi({
      example: 'My page',
    }),
    description: z.string().optional().nullable().openapi({
      example: '',
    }),
    logo: z.string().optional().nullable().openapi({
      example: 'base64...',
    }),
    url: z.string().optional().nullable().openapi({
      example: 'changelog.mydomain.com',
    }),
    changelogIds: z.array(z.string().uuid()),
  })
  .openapi('Page')
