import type { VersionCreateInput } from '@boring.tools/schema'
import {
  Button,
  Calendar,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  cn,
} from '@boring.tools/ui'
import type { z } from '@hono/zod-openapi'
import { format } from 'date-fns'
import { CalendarIcon } from 'lucide-react'
import type { UseFormReturn } from 'react-hook-form'
import { VersionStatus } from '../../VersionStatus'

export const ChangelogVersionFormRelease = ({
  form,
}: { form: UseFormReturn<z.infer<typeof VersionCreateInput>> }) => {
  return (
    <span className="flex flex-col">
      <h2 className="text-lg font-bold mb-2">Release</h2>
      <div className="flex flex-col gap-4">
        <FormField
          control={form.control}
          name="status"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Status</FormLabel>
              <Select
                onValueChange={field.onChange}
                defaultValue={field.value}
                value={field.value}
              >
                <FormControl>
                  <SelectTrigger>
                    <SelectValue placeholder="Select your version status" />
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  <SelectItem value="draft">
                    <div className="flex gap-2 items-center">
                      <VersionStatus status={'draft'} />
                      <span>Draft</span>
                    </div>
                  </SelectItem>
                  <SelectItem value="review">
                    <div className="flex gap-2 items-center">
                      <VersionStatus status={'review'} />
                      <span>Review</span>
                    </div>
                  </SelectItem>
                  <SelectItem value="published">
                    <div className="flex gap-2 items-center">
                      <VersionStatus status={'published'} />
                      <span>Published</span>
                    </div>
                  </SelectItem>
                </SelectContent>
              </Select>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="releasedAt"
          render={({ field }) => (
            <FormItem className="flex flex-col">
              <FormLabel className="mb-2">Released at</FormLabel>
              <Popover>
                <PopoverTrigger asChild>
                  <FormControl>
                    <Button
                      variant={'outline'}
                      size={'lg'}
                      className={cn(
                        'w-full pl-3 pr-3 text-left font-normal',
                        !field.value && 'text-muted-foreground',
                      )}
                    >
                      {field.value ? (
                        format(field.value, 'PPP')
                      ) : (
                        <span className="w-full">Pick a date</span>
                      )}
                      <CalendarIcon className="ml-auto h-4 w-4 opacity-50 shrink-0" />
                    </Button>
                  </FormControl>
                </PopoverTrigger>
                <PopoverContent className="w-auto p-0" align="start">
                  <Calendar
                    mode="single"
                    selected={field.value as Date}
                    onSelect={(date) => field.onChange(date)}
                    weekStartsOn={1}
                  />
                </PopoverContent>
              </Popover>
              <FormMessage />
            </FormItem>
          )}
        />
      </div>
    </span>
  )
}
