import { z } from '@hono/zod-openapi'
import { VersionOutput } from './base'

export const VersionCreateOutput = VersionOutput
export const VersionCreateInput = z
  .object({
    changelogId: z.string().uuid(),
    title: z.string(),
    slug: z.string(),
    version: z.string(),
    content: z.string().optional(),
    status: z
      .enum(['draft', 'review', 'published'])
      .default('draft')
      .optional(),
    releasedAt: z.date().or(z.string()).optional().nullable(),
    commitIds: z.array(z.string()).optional(),
  })
  .openapi({
    required: ['changelogId', 'version', 'content', 'releasedAt'],
  })

export const VersionCreateAutoInput = z
  .object({
    version: z.string().optional(),
    changelogId: z.string().uuid(),
  })
  .openapi({
    required: ['changelogId'],
  })
