import { fileListOptions } from '@boring.tools/hooks'
import {
  Button,
  Card,
  CardContent,
  CardFooter,
  FileUpload,
} from '@boring.tools/ui'
import { useSuspenseQuery } from '@tanstack/react-query'
import { createFileRoute, redirect } from '@tanstack/react-router'
import { DownloadIcon, FileIcon, HardDriveIcon } from 'lucide-react'
import { FileDelete } from '../../components/File/Delete'
import { Layout } from '../../components/Layout'
import { PageWrapper } from '../../components/PageWrapper'
import { humanFileSize } from '../../utils/humanFileSize'

const Component = () => {
  const { data } = useSuspenseQuery(fileListOptions())
  return (
    <Layout>
      <PageWrapper breadcrumbs={[{ name: 'File Explorer', to: '/file' }]}>
        <div className="flex items-end w-full justify-end">
          <div className="max-w-52">
            <FileUpload />
          </div>
        </div>
        {data.length <= 0 && (
          <div className="flex flex-col items-center justify-center py-12">
            <FileIcon
              className="h-20 w-20 shrink-0 text-muted-foreground"
              strokeWidth={1}
            />
            <h3 className="mt-4 text-lg font-semibold text-muted-foreground">
              No files found
            </h3>
            <p className="mt-1 text-sm text-popover-foreground">
              Get started by uploading your first file
            </p>
          </div>
        )}
        <div className="flex gap-5 flex-wrap">
          {data.map((file) => {
            return (
              <Card key={file.key} className=" w-56 relative group">
                <div className="absolute hidden group-hover:flex z-50 w-56 h-full rounded-xl bg-background/50 flex-col">
                  <div className="w-full h-full flex items-center justify-center flex-col gap-5 p-10">
                    <Button variant={'default'} className="w-full" asChild>
                      <a href={file.url} download className="flex gap-2">
                        <DownloadIcon className="w-5 h-5 shrink-0" />
                        Download
                      </a>
                    </Button>
                    <FileDelete id={file.key} />
                  </div>
                </div>
                <CardContent className="flex items-center justify-center mt-5 ">
                  <img
                    src={file.url}
                    alt={file.fileName}
                    className="h-32 w-32 object-contain"
                  />
                </CardContent>
                <CardFooter className="flex flex-col items-start gap-2 border-t p-3">
                  <div className="flex gap-2 text-xs">
                    <FileIcon className="w-4 h-4 shrink-0" />{' '}
                    <span className="line-clamp-1 max-w-[170px]">
                      {file.fileName}
                    </span>
                  </div>
                  <div className="flex gap-2 text-xs">
                    <HardDriveIcon className="w-4 h-4 shrink-0" />{' '}
                    {humanFileSize(file.size)}
                  </div>
                </CardFooter>
              </Card>
            )
          })}
        </div>
      </PageWrapper>
    </Layout>
  )
}

export const Route = createFileRoute('/file/')({
  component: Component,
  beforeLoad: ({ context, location }) => {
    if (!context.auth.user) {
      throw redirect({
        to: '/authentication',
        search: {
          redirect: location.href,
        },
      })
    }
  },
  loader: ({ context }) => {
    return context.queryClient?.ensureQueryData(fileListOptions())
  },
})
