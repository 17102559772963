import { useChangelogById } from '@boring.tools/hooks'
import type { VersionOutput } from '@boring.tools/schema'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@boring.tools/ui'
import type { z } from '@hono/zod-openapi'
import { Link, useParams } from '@tanstack/react-router'
import {
  type ColumnDef,
  type ColumnFiltersState,
  type SortingState,
  type VisibilityState,
  flexRender,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { format } from 'date-fns'
import { useState } from 'react'
import { DataTableColumnHeader } from './Commits/Table/ColumnHeader'
import { DataTablePagination } from './Commits/Table/Pagination'
import { DataTableToolbar } from './Commits/Table/Toolbar'
import { ChangelogVersionCreate } from './Version/Create'
import { VersionStatus } from './VersionStatus'

const columns: ColumnDef<z.infer<typeof VersionOutput>>[] = [
  {
    accessorKey: 'status',
    header: () => null,
    cell: ({ row }) => {
      return (
        <div className="flex space-x-2">
          <span className="max-w-[30px] truncate font-medium">
            <VersionStatus status={row.original.status} />
          </span>
        </div>
      )
    },
  },
  {
    accessorKey: 'title',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Title" />
    ),
    cell: ({ row }) => {
      return (
        <div className="flex space-x-2">
          <Link
            to="/changelog/$id/version/$versionId"
            params={{
              id: row.original.changelogId,
              versionId: row.original.id,
            }}
            className="min-w-[500px] w-full truncate font-medium"
          >
            {row.original.title}
          </Link>
        </div>
      )
    },
  },

  {
    accessorKey: 'version',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Version" />
    ),
    cell: ({ row }) => {
      return (
        <div className="flex space-x-2">
          <span className="truncate font-medium">{row.original.version}</span>
        </div>
      )
    },
  },
  {
    accessorKey: 'releasedAt',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Released At" />
    ),
    cell: ({ row }) => {
      return (
        <div className="flex space-x-2">
          <span className="truncate font-medium">
            {row.original.releasedAt
              ? format(new Date(row.original.releasedAt), 'dd.MM.yyyy')
              : 'N/A'}
          </span>
        </div>
      )
    },
  },
]

export const ChangelogVersionList = () => {
  const { id } = useParams({ from: '/changelog/$id' })
  const { data } = useChangelogById({ id })
  const [rowSelection, setRowSelection] = useState({})
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({})
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])
  const [sorting, setSorting] = useState<SortingState>([])

  const table = useReactTable({
    data: data?.versions as z.infer<typeof VersionOutput>[],
    columns,
    state: {
      sorting,
      columnVisibility,
      rowSelection,
      columnFilters,
    },
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
  })

  if (data) {
    return (
      <div className="flex flex-col gap-5 w-full max-w-screen-2xl">
        <div className="flex items-center justify-between w-full">
          <h2 className="text-2xl">Versions</h2>
          <ChangelogVersionCreate />
        </div>
        <DataTableToolbar table={table} searchKey="title" />
        <div className="rounded-md border ">
          <Table>
            <TableHeader>
              {table.getHeaderGroups().map((headerGroup) => (
                <TableRow key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <TableHead key={header.id} colSpan={header.colSpan}>
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext(),
                            )}
                      </TableHead>
                    )
                  })}
                </TableRow>
              ))}
            </TableHeader>
            <TableBody>
              {table.getRowModel().rows?.length ? (
                table.getRowModel().rows.map((row) => (
                  <TableRow
                    key={row.id}
                    data-state={row.getIsSelected() && 'selected'}
                  >
                    {row.getVisibleCells().map((cell) => (
                      <TableCell key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={columns.length}
                    className="h-24 text-center"
                  >
                    No results.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <DataTablePagination table={table} />
      </div>
    )
  }

  return <div className="flex flex-col gap-5">Not found</div>
}
