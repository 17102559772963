import type {
  ChangelogCreateInput,
  ChangelogOutput,
  ChangelogUpdateInput,
  CommitListParams,
  CommitOutput,
  CommitStatisticsInput,
  CommitStatisticsOutput,
  VersionCreateAutoInput,
  VersionCreateInput,
  VersionOutput,
  VersionUpdateInput,
} from '@boring.tools/schema'
import type { z } from '@hono/zod-openapi'
import {
  queryOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'
import { queryFetch } from '../lib/queryFetch'

type Changelog = z.infer<typeof ChangelogOutput>
type ChangelogCreate = z.infer<typeof ChangelogCreateInput>
type ChangelogUpdate = z.infer<typeof ChangelogUpdateInput>

type Version = z.infer<typeof VersionOutput>
type VersionCreate = z.infer<typeof VersionCreateInput>
type VersionCreateAuto = z.infer<typeof VersionCreateAutoInput>
type VersionUpdate = z.infer<typeof VersionUpdateInput>

type Commit = z.infer<typeof CommitOutput>

export const changelogCommitStatistics = (
  data: z.infer<typeof CommitStatisticsInput>,
) =>
  queryOptions({
    queryKey: ['changelogCommitStatistics', data.changelogId],
    queryFn: async (): Promise<
      Readonly<z.infer<typeof CommitStatisticsOutput>>
    > => {
      return await queryFetch({
        method: 'get',
        path: 'changelog/commit/statistics',
        params: {
          from: data.from as string,
          to: data.to as string,
          changelogId: data.changelogId as string,
        },
      })
    },
  })

export const changelogListOptions = () =>
  queryOptions({
    queryKey: ['changelogList'],
    queryFn: async (): Promise<ReadonlyArray<Changelog>> =>
      await queryFetch({
        method: 'get',
        path: 'changelog',
      }),
  })

export const changelogCommitListOptions = (
  data: z.infer<typeof CommitListParams>,
) =>
  queryOptions({
    queryKey: ['changelogCommitList', data.changelogId],
    queryFn: async (): Promise<ReadonlyArray<Commit>> =>
      await queryFetch({
        method: 'get',
        path: `changelog/commit?changelogId=${data.changelogId}&limit=${data.limit}&offset=${data.offset}`,
      }),
  })

export const useChangelogList = () => {
  return useQuery(changelogListOptions())
}

export const useChangelogCommitList = ({
  id,
  limit,
  offset,
}: {
  id: string
  limit?: number
  offset?: number
}) => {
  return useQuery({
    queryKey: ['changelogCommitList'],
    queryFn: async (): Promise<ReadonlyArray<Commit>> =>
      await queryFetch({
        path: `changelog/commit?changelogId=${id}&limit=${limit}&offset=${offset}`,
        method: 'get',
      }),
  })
}

export const changelogByIdOptions = (id: string) =>
  queryOptions({
    queryKey: ['changelogById', id],
    queryFn: async (): Promise<Readonly<Changelog>> =>
      await queryFetch({
        path: `changelog/${id}`,
        method: 'get',
      }),
  })

export const useChangelogById = ({ id }: { id: string }) => {
  return useQuery({
    queryKey: ['changelogById', id],
    queryFn: async (): Promise<Readonly<Changelog>> =>
      await queryFetch({
        path: `changelog/${id}`,
        method: 'get',
      }),
  })
}

export const useChangelogCreate = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (
      payload: ChangelogCreate,
    ): Promise<Readonly<Changelog>> =>
      await queryFetch({
        path: 'changelog',
        data: payload,
        method: 'post',
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['changelogList'] })
    },
  })
}

export const useChangelogUpdate = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({
      id,
      payload,
    }: {
      id: string
      payload: ChangelogUpdate
    }): Promise<Readonly<Changelog>> =>
      await queryFetch({
        path: `changelog/${id}`,
        data: payload,
        method: 'put',
      }),
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: ['changelogById', data.id],
      })
      queryClient.invalidateQueries({
        queryKey: ['changelogList'],
      })
    },
  })
}

export const useChangelogRemove = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ id }: { id: string }): Promise<Readonly<Changelog>> =>
      await queryFetch({
        path: `changelog/${id}`,
        method: 'delete',
      }),
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: ['changelogList', 'changelogById', data.id],
      })
    },
  })
}

export const useChangelogVersionCreate = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (payload: VersionCreate): Promise<Readonly<Version>> =>
      await queryFetch({
        path: 'changelog/version',
        data: payload,
        method: 'post',
      }),
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ['changelogList'] })
      queryClient.invalidateQueries({
        queryKey: ['changelogById', data.changelogId],
      })
    },
  })
}

export const useChangelogVersionCreateAuto = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (
      payload: VersionCreateAuto,
    ): Promise<Readonly<Version>> =>
      await queryFetch({
        path: 'changelog/version/auto',
        data: payload,
        method: 'post',
      }),
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ['changelogList'] })
      queryClient.invalidateQueries({
        queryKey: ['changelogById', data.changelogId],
      })
    },
  })
}

export const changelogVersionByIdOptions = (id: string) =>
  queryOptions({
    queryKey: ['changelogVersionById', id],
    queryFn: async (): Promise<Readonly<Version>> =>
      await queryFetch({
        path: `changelog/version/${id}`,
        method: 'get',
      }),
  })

export const useChangelogVersionById = ({ id }: { id: string }) => {
  return useQuery({
    queryKey: ['changelogVersionById', id],
    queryFn: async (): Promise<Readonly<Version>> =>
      await queryFetch({
        path: `changelog/version/${id}`,
        method: 'get',
      }),
  })
}

export const useChangelogVersionUpdate = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({
      id,
      payload,
    }: {
      id: string
      payload: VersionUpdate
    }): Promise<Readonly<Version>> =>
      await queryFetch({
        path: `changelog/version/${id}`,
        data: payload,
        method: 'put',
      }),
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: ['changelogById', data.changelogId],
      })

      queryClient.invalidateQueries({
        queryKey: ['changelogVersionById', data.id],
      })
    },
  })
}

export const useChangelogVersionRemove = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ id }: { id: string }): Promise<Readonly<Version>> =>
      await queryFetch({
        path: `changelog/version/${id}`,
        method: 'delete',
      }),
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: ['changelogList', 'changelogById', data.id],
      })
    },
  })
}
