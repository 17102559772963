import type {
  WorkspaceMemberInviteAcceptInput,
  WorkspaceMemberInviteGetOutput,
  WorkspaceMemberInviteGetParams,
  WorkspaceMemberInviteOutput,
  WorkspaceOutput,
  WorkspacePostInput,
  WorkspacePutInput,
  WorkspaceStatisticsOutput,
} from '@boring.tools/schema'
import type { z } from '@hono/zod-openapi'
import {
  queryOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'
import { queryFetch } from '../lib/queryFetch'

export const workspaceGetAllOptions = () =>
  queryOptions({
    queryKey: ['workspace'],
    queryFn: async (): Promise<Readonly<z.infer<typeof WorkspaceOutput>[]>> => {
      return await queryFetch({
        method: 'get',
        path: 'workspace/list',
      })
    },
  })

export const workspaceGetOptions = () =>
  queryOptions({
    queryKey: ['workspace', 'current'],
    queryFn: async (): Promise<Readonly<z.infer<typeof WorkspaceOutput>>> => {
      return await queryFetch({
        method: 'get',
        path: 'workspace',
      })
    },
  })

export const workspaceMemberInviteGetOptions = (
  payload: z.infer<typeof WorkspaceMemberInviteGetParams>,
) =>
  queryOptions({
    queryKey: ['workspace', 'member', 'invite'],
    queryFn: async (): Promise<
      Readonly<z.infer<typeof WorkspaceMemberInviteGetOutput>>
    > => {
      return await queryFetch({
        method: 'get',
        path: `workspace/member/invite?email=${payload.email}&token=${payload.token}`,
      })
    },
  })

export const workspaceMemberInvitesGetOptions = queryOptions({
  queryKey: ['workspace', 'member', 'invites'],
  queryFn: async (): Promise<
    Readonly<z.infer<typeof WorkspaceMemberInviteOutput>[]>
  > => {
    return await queryFetch({
      method: 'get',
      path: 'workspace/member/invites',
    })
  },
})

export const workspaceStatisticsGetOptions = queryOptions({
  queryKey: ['workspace', 'statistics'],
  queryFn: async (): Promise<
    Readonly<z.infer<typeof WorkspaceStatisticsOutput>>
  > => {
    return await queryFetch({
      method: 'get',
      path: 'workspace/statistics',
    })
  },
})

export const useWorkspaceCreate = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (
      payload: z.infer<typeof WorkspacePostInput>,
    ): Promise<Readonly<z.infer<typeof WorkspaceOutput>>> => {
      return await queryFetch({
        method: 'post',
        path: 'workspace',
        data: payload,
      })
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['workspace'] })
      queryClient.invalidateQueries({ queryKey: ['user'] })
    },
  })
}

export const useWorkspaceUpdate = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({
      id,
      payload,
    }: {
      id: string
      payload: z.infer<typeof WorkspacePutInput>
    }): Promise<Readonly<z.infer<typeof WorkspaceOutput>>> => {
      return await queryFetch({
        method: 'put',
        path: `workspace/${id}`,
        data: payload,
      })
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['workspace'] })
    },
  })
}

export const useWorkspaceDelete = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (id: string): Promise<Readonly<boolean>> => {
      return await queryFetch({
        method: 'delete',
        path: `workspace/${id}`,
      })
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['workspace'] })
    },
  })
}

export const useWorkspaceLogoPost = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (logo: File): Promise<void> => {
      const data = new FormData()
      data.append('file', logo)

      return await queryFetch({
        method: 'post',
        path: 'workspace/logo',
        data,
      })
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['workspace'],
      })
    },
  })
}
export const useWorkspaceMemberDelete = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (
      id: string,
    ): Promise<Readonly<z.infer<typeof WorkspaceOutput>>> => {
      return await queryFetch({
        method: 'delete',
        path: `workspace/member/${id}`,
      })
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['workspace'] })
      queryClient.invalidateQueries({ queryKey: ['user'] })
    },
  })
}

export const useWorkspaceMemberInviteAccept = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (
      payload: z.infer<typeof WorkspaceMemberInviteAcceptInput>,
    ): Promise<void> => {
      return await queryFetch({
        method: 'put',
        path: 'workspace/member/invite',
        data: payload,
      })
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['workspace'] })
      queryClient.invalidateQueries({ queryKey: ['user'] })
    },
  })
}

export const useWorkspaceMemberInviteDelete = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (email: string): Promise<void> => {
      return await queryFetch({
        method: 'delete',
        path: `workspace/member/invite/${email}`,
      })
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['workspace', 'member', 'invites'],
      })
    },
  })
}

export const useWorkspaceMemberInvitePost = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (emails: string): Promise<void> => {
      return await queryFetch({
        method: 'post',
        path: 'workspace/member/invite',
        data: { emails },
      })
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['workspace', 'member', 'invites'],
      })
    },
  })
}
