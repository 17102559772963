import {
  useAuth,
  useWorkspaceMemberInviteAccept,
  workspaceMemberInviteGetOptions,
} from '@boring.tools/hooks'
import { WorkspaceMemberInviteGetParams } from '@boring.tools/schema'
import {
  Alert,
  Button,
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from '@boring.tools/ui'
import { useQuery } from '@tanstack/react-query'
import {
  Link,
  createFileRoute,
  useNavigate,
  useSearch,
} from '@tanstack/react-router'
import { LoaderCircleIcon } from 'lucide-react'

const Component = () => {
  const auth = useAuth()
  const search = useSearch({ from: '/workspace/invite' })
  const { data, isError, error, isLoading } = useQuery(
    workspaceMemberInviteGetOptions(search),
  )
  const accept = useWorkspaceMemberInviteAccept()
  const navigate = useNavigate()

  const handleAccept = async () => {
    accept.mutate(
      {
        email: search.email,
        token: search.token,
        accepted: true,
      },
      {
        onSuccess: () => {
          window.location.replace('/')
        },
      },
    )
  }

  const handleDecline = async () => {
    accept.mutate(
      {
        email: search.email,
        token: search.token,
        accepted: false,
      },
      {
        onSuccess: () => {
          navigate({ to: '/' })
        },
      },
    )
  }

  if (isLoading) {
    return (
      <div className="flex flex-col items-center justify-center w-full h-screen">
        <div className="w-full max-w-md flex flex-col gap-5">
          <div className="flex flex-col">
            <h1 className="text-2xl">boring.tools</h1>
            <h2 className="text-muted-foreground">Workspace invite</h2>
          </div>
          <LoaderCircleIcon className="w-8 h-8 animate-spin" />
        </div>
      </div>
    )
  }

  if (isError) {
    return (
      <div className="flex flex-col items-center justify-center w-full h-screen">
        <div className="w-full max-w-md flex flex-col gap-5">
          <div className="flex flex-col">
            <h1 className="text-2xl">boring.tools</h1>
            <h2 className="text-muted-foreground">Workspace invite</h2>
          </div>
          <Alert variant="destructive">{error.message}</Alert>
        </div>
      </div>
    )
  }

  return (
    <div className="flex flex-col items-center justify-center h-screen w-full">
      <Card className="max-w-md">
        <CardHeader>
          <CardTitle>Workspace invite</CardTitle>
        </CardHeader>
        <CardContent>
          <p className="text-muted-foreground">
            You have been invited to the following workspace:{' '}
            <span className="text-primary font-bold">{data?.name}</span>
          </p>
          {!auth.user && (
            <div className="my-3">
              <p>
                You need an account to accept the invitation. Please create one
                or if you already have an account, please log in.
              </p>

              <Button type="button" asChild>
                <Link
                  to="/authentication"
                  from="/workspace/invite"
                  params={{ email: search.email, token: search.token }}
                >
                  Sign in
                </Link>
              </Button>
            </div>
          )}
          {auth.user && (
            <div className="my-3">
              <p>Would you like to accept the invitation?</p>

              <div className="flex justify-between items-center mt-4">
                <Button
                  type="button"
                  variant={'destructive'}
                  onClick={handleDecline}
                >
                  Decline invitation
                </Button>

                <Button
                  type="button"
                  variant={'secondary'}
                  onClick={handleAccept}
                >
                  Accept invitation
                </Button>
              </div>
            </div>
          )}
        </CardContent>
      </Card>
    </div>
  )
}

export const Route = createFileRoute('/workspace/invite')({
  component: Component,
  validateSearch: WorkspaceMemberInviteGetParams,
  errorComponent: () => <div>Required Parameters missing</div>,
})
