import { useWorkspaceDelete } from '@boring.tools/hooks'
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
  Button,
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  toast,
} from '@boring.tools/ui'
import { useRouteContext } from '@tanstack/react-router'
import { useState } from 'react'

export const WorkspaceSettingsDelete = () => {
  const [isOpen, setIsOpen] = useState(false)
  const { auth } = useRouteContext({ from: '/workspace/' })
  const deleteWorkspace = useWorkspaceDelete()
  const handleDelete = () => {
    deleteWorkspace.mutate(auth.user?.currentWorkspaceId as string, {
      onError: (error) => {
        toast.error(error.message)
      },
      onSuccess: () => {
        toast.success('Workspace deleted')
        setIsOpen(false)
        window.location.replace('/')
      },
    })
  }
  return (
    <Card>
      <CardHeader>
        <CardTitle>Delete workspace</CardTitle>
      </CardHeader>
      <CardContent>
        If you want to permanently delete this workspace, click the button
        below.
        <br />
        <AlertDialog open={isOpen} onOpenChange={() => setIsOpen(!isOpen)}>
          <AlertDialogTrigger asChild>
            <Button type="button" variant="destructive" className="mt-3">
              Delete this workspace
            </Button>
          </AlertDialogTrigger>
          <AlertDialogContent>
            <AlertDialogHeader>
              <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
              <AlertDialogDescription>
                This action cannot be undone. This will permanently delete your
                workspace and remove your data from our servers.
              </AlertDialogDescription>
            </AlertDialogHeader>
            <AlertDialogFooter>
              <AlertDialogCancel>Cancel</AlertDialogCancel>
              <AlertDialogAction onClick={handleDelete}>
                Delete
              </AlertDialogAction>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
      </CardContent>
    </Card>
  )
}
