import {
  changelogByIdOptions,
  useChangelogCommitList,
  useChangelogVersionCreate,
} from '@boring.tools/hooks'
import { VersionCreateInput } from '@boring.tools/schema'
import {
  Button,
  Checkbox,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  MinimalTiptapEditor,
  ScrollArea,
  Separator,
} from '@boring.tools/ui'
import type { z } from '@hono/zod-openapi'
import { zodResolver } from '@hookform/resolvers/zod'
import { useSuspenseQuery } from '@tanstack/react-query'
import { createFileRoute, redirect, useNavigate } from '@tanstack/react-router'
import { useForm } from 'react-hook-form'
import { ChangelogVersionFormDetails } from '../../../components/Changelog/Version/Form/Details'
import { ChangelogVersionFormRelease } from '../../../components/Changelog/Version/Form/Release'
import { Layout } from '../../../components/Layout'
import { PageWrapper } from '../../../components/PageWrapper'

const Component = () => {
  const { id } = Route.useParams()
  const navigate = useNavigate()
  const versionCreate = useChangelogVersionCreate()
  const ChangelogQuery = useSuspenseQuery(changelogByIdOptions(id))
  const { data } = useChangelogCommitList({ id })
  const form = useForm<z.infer<typeof VersionCreateInput>>({
    resolver: zodResolver(VersionCreateInput),
    defaultValues: {
      changelogId: id,
      version: '',
      content: '',
      status: 'draft',
      commitIds: [],
    },
  })

  // const selectAllCommits = () => {
  //   const commitIds = data?.map((commit) => commit.id)
  //   if (!commitIds) {
  //     return form.setValue('commitIds', [])
  //   }
  //   form.setValue('commitIds', commitIds)
  // }

  const onSubmit = (values: z.infer<typeof VersionCreateInput>) => {
    versionCreate.mutate(values, {
      onSuccess(data) {
        navigate({ to: '/changelog/$id', params: { id: data.changelogId } })
      },
    })
  }

  return (
    <Layout>
      <PageWrapper
        className="p-0 lg:p-0"
        breadcrumbs={[
          { name: 'Changelog', to: '/changelog' },
          { name: ChangelogQuery.data?.title ?? '', to: `/changelog/${id}` },
          { name: 'New version', to: `/changelog/${id}/versionCreate` },
        ]}
      >
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="w-full flex h-full"
          >
            <div className="w-full max-w-80 min-w-56 border-r border-muted p-5 justify-between flex flex-col h-full">
              <div>
                <ChangelogVersionFormDetails form={form} />
                {/* <ChangelogVersionFormMeta form={form} /> */}
                <ChangelogVersionFormRelease form={form} />
              </div>

              <div className="flex flex-col gap-5">
                <div className="flex gap-5 mt-5 w-full justify-end items-end md:col-span-6">
                  <Button
                    type="button"
                    variant={'ghost'}
                    onClick={() =>
                      navigate({ to: '/changelog/$id', params: { id } })
                    }
                  >
                    Cancel
                  </Button>
                  <Button type="submit">Create</Button>
                </div>
              </div>
            </div>

            <div className="w-full">
              <h2 className="text-lg font-bold mb-2 p-5">Release Notes</h2>
              <Separator />
              <FormField
                control={form.control}
                name="content"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="sr-only">Changes</FormLabel>
                    <FormControl>
                      <MinimalTiptapEditor
                        value={field.value}
                        onChange={field.onChange}
                        className="w-full border-none"
                        editorContentClassName="p-5 w-full"
                        output="html"
                        placeholder="Type your release notes here..."
                        editable={true}
                        editorClassName="focus:outline-none"
                      />
                    </FormControl>{' '}
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            <div className="w-full max-w-80 min-w-56 border-l border-muted p-5 h-full">
              <h2 className="text-lg font-bold mb-2">Commits</h2>
              <ScrollArea className="w-full h-[350px]">
                <div className="flex flex-col gap-2">
                  {data?.length === 0 && <div>No commits found</div>}
                  {data?.map((commit) => {
                    return (
                      <FormField
                        key={commit.id}
                        control={form.control}
                        name={'commitIds'}
                        render={({ field }) => {
                          return (
                            <FormItem className="flex flex-row items-start space-x-3 space-y-0 rounded-md ">
                              <FormControl>
                                <Checkbox
                                  value={commit.id}
                                  checked={field.value?.includes(commit.id)}
                                  onCheckedChange={() => {
                                    const exist = field.value?.includes(
                                      commit.id,
                                    )
                                    if (exist) {
                                      return field.onChange(
                                        field.value?.filter(
                                          (value) => value !== commit.id,
                                        ),
                                      )
                                    }
                                    return field.onChange([
                                      ...(field?.value as string[]),
                                      commit.id,
                                    ])
                                  }}
                                />
                              </FormControl>
                              <div className="space-y-1 leading-none">
                                <FormLabel>{commit.description}</FormLabel>
                              </div>
                            </FormItem>
                          )
                        }}
                      />
                    )
                  })}
                </div>
              </ScrollArea>
            </div>
          </form>
        </Form>
      </PageWrapper>
    </Layout>
  )
}

export const Route = createFileRoute('/changelog_/$id/versionCreate')({
  component: Component,
  beforeLoad: ({ context, location }) => {
    if (!context.auth.user) {
      throw redirect({
        to: '/authentication',
        search: {
          redirect: location.href,
        },
      })
    }
  },
  loader: ({ context, params }) => {
    return context.queryClient?.ensureQueryData(changelogByIdOptions(params.id))
  },
})
