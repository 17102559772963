import { useFileDelete } from '@boring.tools/hooks'
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
  Button,
} from '@boring.tools/ui'
import { TrashIcon } from 'lucide-react'
import { useState } from 'react'

export const FileDelete = ({ id }: { id: string }) => {
  const fileDelete = useFileDelete()
  const [isOpen, setIsOpen] = useState(false)

  const removeFile = () => {
    fileDelete.mutate(
      { id },
      {
        onSuccess: () => {
          setIsOpen(false)
        },
      },
    )
  }
  return (
    <AlertDialog open={isOpen}>
      <AlertDialogTrigger asChild>
        <Button
          variant={'destructive'}
          onClick={() => setIsOpen(true)}
          className="w-full flex gap-2"
        >
          <TrashIcon strokeWidth={1.5} className="w-5 h-5 shrink-0" />
          Delete
        </Button>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
          <AlertDialogDescription>
            This action cannot be undone. This will permanently delete your file
            and remove your data from our servers.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel onClick={() => setIsOpen(false)}>
            Cancel
          </AlertDialogCancel>
          <AlertDialogAction asChild>
            <Button onClick={removeFile} variant={'destructive'}>
              Delete
            </Button>
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}
