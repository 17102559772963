import * as React from 'react'
import './styles/index.css'

import type { Content, Editor } from '@tiptap/react'
import { EditorContent } from '@tiptap/react'
import { cn } from '../lib/cn'
import { Separator } from '../separator'
import { LinkBubbleMenu } from './components/bubble-menu/link-bubble-menu'
import { MeasuredContainer } from './components/measured-container'
import { SectionFive } from './components/section/five'
import { SectionFour } from './components/section/four'
import { SectionOne } from './components/section/one'
import { SectionThree } from './components/section/three'
import { SectionTwo } from './components/section/two'
import type { UseMinimalTiptapEditorProps } from './hooks/use-minimal-tiptap'
import { useMinimalTiptapEditor } from './hooks/use-minimal-tiptap'

export interface MinimalTiptapProps
  extends Omit<UseMinimalTiptapEditorProps, 'onUpdate'> {
  value?: Content
  onChange?: (value: Content) => void
  className?: string
  editorContentClassName?: string
  height?: number
}

const Toolbar = ({ editor }: { editor: Editor }) => (
  <div className="shrink-0 overflow-x-auto border-b border-border p-2 w-full">
    <div className="flex w-max items-center gap-px">
      <SectionOne editor={editor} activeLevels={[1, 2, 3, 4, 5, 6]} />

      <Separator orientation="vertical" className="mx-2 h-7" />

      <SectionTwo
        editor={editor}
        activeActions={[
          'bold',
          'italic',
          'underline',
          'strikethrough',
          'code',
          'clearFormatting',
        ]}
        mainActionCount={3}
      />

      <Separator orientation="vertical" className="mx-2 h-7" />

      <SectionThree editor={editor} />

      <Separator orientation="vertical" className="mx-2 h-7" />

      <SectionFour
        editor={editor}
        activeActions={['orderedList', 'bulletList']}
        mainActionCount={0}
      />

      <Separator orientation="vertical" className="mx-2 h-7" />

      <SectionFive
        editor={editor}
        activeActions={['codeBlock', 'blockquote', 'horizontalRule']}
        mainActionCount={0}
      />
    </div>
  </div>
)

export const MinimalTiptapEditor = React.forwardRef<
  HTMLDivElement,
  MinimalTiptapProps
>(
  (
    {
      value,
      onChange,
      className,
      editorContentClassName,
      height = 800,
      ...props
    },
    ref,
  ) => {
    const editor = useMinimalTiptapEditor({
      onUpdate: onChange,
      ...props,
    })
    React.useEffect(() => {
      if (editor?.isEmpty && value) {
        editor?.commands.setContent(value)
      }
    }, [value, editor])

    if (!editor) {
      return null
    }

    return (
      <MeasuredContainer
        as="div"
        name="editor"
        ref={ref}
        style={{ height: height - 100 }}
        className={cn(
          'flex min-h-72 w-full flex-col rounded-md border border-input shadow-sm focus-within:border-primary items-center ',
          className,
        )}
      >
        <Toolbar editor={editor} />
        <EditorContent
          editor={editor}
          className={cn(
            'minimal-tiptap-editor max-w-screen-lg overflow-scroll',
            editorContentClassName,
          )}
        />
        <LinkBubbleMenu editor={editor} />
      </MeasuredContainer>
    )
  },
)

MinimalTiptapEditor.displayName = 'MinimalTiptapEditor'

export default MinimalTiptapEditor
