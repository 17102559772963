import {
  changelogByIdOptions,
  changelogCommitListOptions,
} from '@boring.tools/hooks'
import type { CommitOutput, VersionOutput } from '@boring.tools/schema'
import {
  Badge,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@boring.tools/ui'
import type { z } from '@hono/zod-openapi'
import { useSuspenseQuery } from '@tanstack/react-query'
import {
  Link,
  createFileRoute,
  redirect,
  useParams,
} from '@tanstack/react-router'
import {
  type ColumnDef,
  type ColumnFiltersState,
  type SortingState,
  type VisibilityState,
  flexRender,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { format } from 'date-fns'
import { GitCommitVerticalIcon } from 'lucide-react'
import { useState } from 'react'
import { DataTableColumnHeader } from '../../../components/Changelog/Commits/Table/ColumnHeader'
import { DataTablePagination } from '../../../components/Changelog/Commits/Table/Pagination'
import { DataTableToolbar } from '../../../components/Changelog/Commits/Table/Toolbar'
import { Layout } from '../../../components/Layout'
import { PageWrapper } from '../../../components/PageWrapper'

const columns: ColumnDef<z.infer<typeof CommitOutput>>[] = [
  {
    accessorKey: 'description',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Description" />
    ),
    cell: ({ row }) => {
      const type = row.original.type
      const scope = row.original.scope
      const description: string = row.getValue('description')

      return (
        <div className="flex space-x-2">
          {type && <Badge variant="outline">{type}</Badge>}
          {scope && <Badge variant="outline">{scope}</Badge>}
          <span className="min-w-[500px] truncate font-medium">
            {description}
          </span>
        </div>
      )
    },
  },

  {
    accessorKey: 'version',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Version" />
    ),
    cell: ({ row }) => {
      const changelogId: string = row.original.changelogId
      const version: z.infer<typeof VersionOutput> | null =
        row.getValue('version')
      return (
        <div className="flex space-x-2">
          <span className="w-[80px] truncate font-medium">
            {version ? (
              <Badge variant={'default'}>
                {/* @ts-ignore */}
                <Link
                  to="/changelog/$id/version/$versionId"
                  params={{ id: changelogId, versionId: version.id }}
                >
                  {version?.version}
                </Link>
              </Badge>
            ) : null}
          </span>
        </div>
      )
    },
  },

  {
    accessorKey: 'author',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Author" />
    ),
    cell: ({ row }) => {
      return (
        <div className="flex space-x-2">
          <span className="max-w-[100px] truncate font-medium">
            {
              (row.getValue('author') as z.infer<typeof CommitOutput>['author'])
                .name
            }
          </span>
        </div>
      )
    },
  },

  {
    accessorKey: 'date',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Date" />
    ),
    size: 100,
    cell: ({ row }) => {
      return (
        <div className="flex space-x-2">
          <span className="w-[145px] truncate font-medium tabular-nums">
            {format(
              new Date(
                (
                  row.getValue('author') as z.infer<
                    typeof CommitOutput
                  >['author']
                ).date,
              ),
              'HH:mm:ss - dd.MM.yyyy',
            )}
          </span>
        </div>
      )
    },
  },
]

const Component = () => {
  const { id } = useParams({ from: '/changelog_/$id/commits' })
  const { data } = useSuspenseQuery(
    changelogCommitListOptions({ changelogId: id }),
  )
  const { data: changelogData } = useSuspenseQuery(changelogByIdOptions(id))
  const [rowSelection, setRowSelection] = useState({})
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({})
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])
  const [sorting, setSorting] = useState<SortingState>([])

  const table = useReactTable({
    data: data as z.infer<typeof CommitOutput>[],
    columns,
    state: {
      sorting,
      columnVisibility,
      rowSelection,
      columnFilters,
    },
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
  })
  return (
    <Layout>
      <PageWrapper
        breadcrumbs={[
          { name: 'Changelog', to: '/changelog' },
          { name: changelogData?.title, to: `/changelog/${changelogData?.id}` },
          { name: 'Commits', to: `/changelog/${changelogData?.id}/commits` },
        ]}
      >
        <div className="flex flex-col gap-5 w-full max-w-screen-2xl">
          <div className="flex gap-3 items-center">
            <GitCommitVerticalIcon
              strokeWidth={1.5}
              className="w-8 h-8 text-muted-foreground"
            />
            <div>
              <h1 className="text-2xl">Commits</h1>
            </div>
          </div>

          <DataTableToolbar table={table} searchKey="description" />
          <div className="rounded-md border ">
            <Table>
              <TableHeader>
                {table.getHeaderGroups().map((headerGroup) => (
                  <TableRow key={headerGroup.id}>
                    {headerGroup.headers.map((header) => {
                      return (
                        <TableHead key={header.id} colSpan={header.colSpan}>
                          {header.isPlaceholder
                            ? null
                            : flexRender(
                                header.column.columnDef.header,
                                header.getContext(),
                              )}
                        </TableHead>
                      )
                    })}
                  </TableRow>
                ))}
              </TableHeader>
              <TableBody>
                {table.getRowModel().rows?.length ? (
                  table.getRowModel().rows.map((row) => (
                    <TableRow
                      key={row.id}
                      data-state={row.getIsSelected() && 'selected'}
                    >
                      {row.getVisibleCells().map((cell) => (
                        <TableCell key={cell.id}>
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext(),
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell
                      colSpan={columns.length}
                      className="h-24 text-center"
                    >
                      No results.
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
          <DataTablePagination table={table} />
        </div>
      </PageWrapper>
    </Layout>
  )
}

export const Route = createFileRoute('/changelog_/$id/commits')({
  component: Component,
  beforeLoad: ({ context, location }) => {
    if (!context.auth.user) {
      throw redirect({
        to: '/authentication',
        search: {
          redirect: location.href,
        },
      })
    }
  },
  loader: ({ context, params }) => {
    context.queryClient?.ensureQueryData(
      changelogCommitListOptions({ changelogId: params.id }),
    )
    context.queryClient?.ensureQueryData(changelogByIdOptions(params.id))
  },
})
