import { useFileUpload } from '@boring.tools/hooks'
import { LoaderCircleIcon, UploadIcon, XIcon } from 'lucide-react'
import { useState } from 'react'
import { type FileWithPath, useDropzone } from 'react-dropzone'
import { Button } from '../../button'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '../../dialog'
import { humanFileSize } from '../../lib/humanFileSize'

export const FileUpload = () => {
  const fileUpload = useFileUpload()
  const [isOpen, setIsOpen] = useState(false)
  const [files, setFiles] = useState<FileWithPath[]>([])
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/*': [],
    },
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          }),
        ),
      )
    },
  })

  const filesList = files.map((file, index) => (
    <li key={file.path} className="flex justify-between items-center ">
      <div className="line-clamp-1 max-w-[340px]">{file.path}</div>

      <div className="flex gap-4">
        {humanFileSize(file.size)}

        <Button
          variant={'ghost-destructive'}
          size={'xs'}
          onClick={() => setFiles((prev) => prev.filter((_, i) => i !== index))}
        >
          <XIcon className="w-4 h-4 shrink-0" />
        </Button>
      </div>
    </li>
  ))
  const uploadFiles = () => {
    for (const file of files) {
      fileUpload.mutate(file, {
        onSuccess: () => {
          setIsOpen(false)
          setFiles([])
        },
      })
    }
  }
  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>
        <Button
          variant={'default'}
          size={'sm'}
          onClick={() => setIsOpen(true)}
          className="flex gap-2 w-full"
        >
          <UploadIcon strokeWidth={1.5} className="w-5 h-5 shrink-0" />
          Upload file
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>File upload</DialogTitle>
          <DialogDescription>
            Drag 'n' drop some files here, or click to select files{' '}
          </DialogDescription>
        </DialogHeader>
        <div
          {...getRootProps({
            className:
              'border border-dashed border-2 border-muted-foreground rounded flex items-center justify-center flex-col p-10',
          })}
        >
          <input {...getInputProps()} />
          <UploadIcon
            className="w-20 h-20 shrink-0 text-muted-foreground"
            strokeWidth={1}
          />
        </div>

        {filesList.length > 0 && (
          <div>
            <h3 className="font-bold">Files</h3>
            <ul className="flex flex-col gap-3 mt-3">{filesList}</ul>
          </div>
        )}

        <DialogFooter className="mt-5">
          <Button
            variant={'default'}
            onClick={uploadFiles}
            disabled={fileUpload.isPending}
          >
            {fileUpload.isPending ? (
              <LoaderCircleIcon className="animate-spin h-5 w-5 shrink-0" />
            ) : (
              'Upload'
            )}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
