import type {
  PageByIdOutput,
  PageCreateInput,
  PageListOutput,
  PageOutput,
  PageUpdateInput,
} from '@boring.tools/schema'
import {
  queryOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'
import type { z } from 'zod'
import { queryFetch } from '../lib/queryFetch'

type Page = z.infer<typeof PageOutput>
type PageList = z.infer<typeof PageListOutput>
type PageById = z.infer<typeof PageByIdOutput>
type PageCreate = z.infer<typeof PageCreateInput>
type PageUpdate = z.infer<typeof PageUpdateInput>

export const pageByIdOptions = (id: string) =>
  queryOptions({
    queryKey: ['pageById', id],
    queryFn: async (): Promise<Readonly<PageById>> =>
      await queryFetch({
        path: `page/${id}`,
        method: 'get',
      }),
  })

export const usePageList = () => {
  return useQuery({
    queryKey: ['pageList'],
    queryFn: async (): Promise<Readonly<PageList>> =>
      await queryFetch({
        path: 'page',
        method: 'get',
      }),
  })
}

export const usePageById = ({ id }: { id: string }) => {
  return useQuery({
    queryKey: ['pageById', id],
    queryFn: async (): Promise<Readonly<PageById>> =>
      await queryFetch({
        path: `page/${id}`,
        method: 'get',
      }),
  })
}

export const usePageCreate = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (payload: PageCreate): Promise<Readonly<Page>> =>
      await queryFetch({
        path: 'page',
        data: payload,
        method: 'post',
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['pageList'] })
    },
  })
}

export const usePageDelete = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ id }: { id: string }): Promise<Readonly<Page>> =>
      await queryFetch({
        path: `page/${id}`,
        method: 'delete',
      }),
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: ['pageList'],
      })
      queryClient.invalidateQueries({
        queryKey: ['pageById', data.id],
      })
    },
  })
}

export const usePageUpdate = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({
      id,
      payload,
    }: {
      id: string
      payload: PageUpdate
    }): Promise<Readonly<Page>> =>
      await queryFetch({
        path: `page/${id}`,
        data: payload,
        method: 'put',
      }),
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: ['pageById', data.id],
      })
      queryClient.invalidateQueries({
        queryKey: ['pageList'],
      })
    },
  })
}

export const usePageLogoPost = (id: string) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (logo: File): Promise<void> => {
      const data = new FormData()
      data.append('file', logo)

      return await queryFetch({
        method: 'post',
        path: `page/${id}/logo`,
        data,
      })
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['page'],
      })
    },
  })
}
