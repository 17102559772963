import { workspaceStatisticsGetOptions } from '@boring.tools/hooks'
import { Card, CardContent, CardHeader, CardTitle } from '@boring.tools/ui'
import { useQuery } from '@tanstack/react-query'

export const DashboardStatistics = () => {
  const { data } = useQuery(workspaceStatisticsGetOptions)

  const getCountNumber = (type: 'changelogs' | 'commits' | 'versions') => {
    if (data) {
      return data[type]
    }
    return 0
  }

  return (
    <div className="grid gap-5 grid-cols-3 lg:grid-cols-6">
      <Card>
        <CardHeader>
          <CardTitle>Changelogs</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="text-2xl font-bold">
            {getCountNumber('changelogs')}
          </div>
        </CardContent>
      </Card>
      <Card>
        <CardHeader>
          <CardTitle>Commits</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="text-2xl font-bold">{getCountNumber('commits')}</div>
        </CardContent>
      </Card>
      <Card>
        <CardHeader>
          <CardTitle>Versions</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="text-2xl font-bold">{getCountNumber('versions')}</div>
        </CardContent>
      </Card>
    </div>
  )
}
