import { z } from '@hono/zod-openapi'

export const FileOutput = z
  .object({
    key: z.string().openapi({
      example: 'some',
    }),
    url: z.string().url(),
    size: z.number().openapi({
      example: 100,
    }),
    type: z.string(),
    extension: z.string(),
    fileName: z.string(),
  })
  .openapi('File')
