import { useWorkspaceLogoPost } from '@boring.tools/hooks'
import { WorkspaceLogoInput, type WorkspaceOutput } from '@boring.tools/schema'
import {
  Button,
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  toast,
} from '@boring.tools/ui'
import type { z } from '@hono/zod-openapi'
import { zodResolver } from '@hookform/resolvers/zod'
import { useRouteContext } from '@tanstack/react-router'
import { LoaderCircleIcon } from 'lucide-react'
import { useForm } from 'react-hook-form'

export const WorkspaceSettingsLogo = ({
  data,
}: { data: z.infer<typeof WorkspaceOutput> }) => {
  const { auth } = useRouteContext({ from: '/workspace/' })
  const upload = useWorkspaceLogoPost()
  const form = useForm<z.infer<typeof WorkspaceLogoInput>>({
    resolver: zodResolver(WorkspaceLogoInput),
    defaultValues: { file: undefined },
  })

  const onSubmit = (values: z.infer<typeof WorkspaceLogoInput>) => {
    if (!auth.user?.currentWorkspaceId) {
      return
    }
    upload.mutate(values.file[0], {
      onSuccess: () => {
        toast.success('Workspace logo updated')
      },
    })
  }
  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
        {(data.logo || form.watch('file')?.[0]) && (
          <img
            alt="logo"
            src={
              form.watch('file')
                ? URL.createObjectURL(form.watch('file')[0])
                : (data.logo as string)
            }
            className="w-32 h-32 rounded-lg object-fill bg-muted p-5"
          />
        )}

        <FormField
          control={form.control}
          name="file"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Select image</FormLabel>
              <FormControl>
                <Input
                  placeholder=""
                  {...field}
                  type="file"
                  value={undefined}
                  onChange={(event) => field.onChange(event.target.files)}
                />
              </FormControl>
              <FormDescription>
                Possible formats: .png, .jpg, .jpeg, .svg
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />

        <Button type="submit" disabled={upload.isPending}>
          {upload.isPending ? (
            <LoaderCircleIcon className="w-4 h-4 animate-spin" />
          ) : (
            'Upload'
          )}
        </Button>
      </form>
    </Form>
  )
}
