import { usePageById, usePageUpdate } from '@boring.tools/hooks'
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@boring.tools/ui'
import { createFileRoute } from '@tanstack/react-router'
import { Link } from '@tanstack/react-router'
import {
  CircleMinusIcon,
  FileStackIcon,
  Globe2Icon,
  PencilIcon,
} from 'lucide-react'
import { Layout } from '../../components/Layout'
import { PageWrapper } from '../../components/PageWrapper'

const Component = () => {
  const { id } = Route.useParams()
  const { data, error, isPending, refetch } = usePageById({ id })
  const pageUpdate = usePageUpdate()
  const removeChangelog = (idToRemove: string) => {
    const payload = {
      name: data?.name,
      description: data?.description,
      changelogIds: data?.changelogs
        .filter((log) => log.id !== idToRemove)
        .map((l) => l.id),
    }
    pageUpdate.mutate({ id, payload })
  }

  const getPageUrl = () => {
    if (data) {
      if (data.url) {
        if (import.meta.env.DEV) {
          return `http://${data.url}`
        }
        return `https://${data.url}`
      }
    }

    if (import.meta.env.DEV) {
      return `http://localhost:4020/${id}`
    }
    return `https://page.boring.tools/${id}`
  }

  if (error) {
    return (
      <div className="flex items-center justify-center mt-32 flex-col">
        <h1 className="text-3xl">Changelogs</h1>
        <p>Please try again later</p>

        <Button onClick={() => refetch()}>Retry</Button>
      </div>
    )
  }

  return (
    <Layout>
      <PageWrapper
        breadcrumbs={[
          {
            name: 'Page',
            to: '/page',
          },
          { name: data?.name ?? '', to: `/page/${data?.id}` },
        ]}
      >
        <div className="flex flex-col gap-5">
          {!isPending && data && (
            <div>
              <div className="flex justify-between items-center">
                <div className="flex gap-3 items-center">
                  <FileStackIcon
                    strokeWidth={1.5}
                    className="w-10 h-10 text-muted-foreground"
                  />
                  <div>
                    <h1 className="text-3xl">{data.name}</h1>

                    <p className="text-muted-foreground mt-2">
                      {data.description}
                    </p>
                  </div>
                </div>

                <div className="flex items-center gap-3">
                  {/* <Tooltip>
                <TooltipTrigger asChild>
                  <Button variant={'ghost'}>
                    <TerminalSquareIcon strokeWidth={1.5} />
                  </Button>
                </TooltipTrigger>
                <TooltipContent>
                  <p>CLI</p>
                </TooltipContent>
              </Tooltip>

               */}

                  <Tooltip>
                    <TooltipTrigger asChild>
                      <Button variant={'ghost'} asChild>
                        <a href={getPageUrl()} target="_blank" rel="noreferrer">
                          <Globe2Icon strokeWidth={1.5} />
                        </a>
                      </Button>
                    </TooltipTrigger>
                    <TooltipContent>
                      <p>Public Page</p>
                    </TooltipContent>
                  </Tooltip>

                  <Tooltip>
                    <TooltipTrigger asChild>
                      <Link to={'/page/$id/edit'} params={{ id }}>
                        <Button variant={'ghost'}>
                          <PencilIcon strokeWidth={1.5} />
                        </Button>
                      </Link>
                    </TooltipTrigger>
                    <TooltipContent>
                      <p>Edit</p>
                    </TooltipContent>
                  </Tooltip>

                  {/* <PageDelete id={id} /> */}
                </div>
              </div>
              <div className="mt-5">
                {/* <Outlet /> */}
                <div className="flex flex-col gap-5">
                  {!isPending && data && (
                    <div>
                      <Card className="w-full max-w-screen-sm">
                        <CardHeader>
                          <div className="flex items-center justify-between">
                            <CardTitle>
                              Changelogs ({data.changelogs?.length ?? 0})
                            </CardTitle>
                          </div>
                        </CardHeader>
                        <CardContent>
                          <div className="flex flex-col gap-1">
                            {data.changelogs?.map((changelog) => {
                              return (
                                <div className="flex gap-3" key={changelog.id}>
                                  <Link
                                    className="hover:bg-muted py-1 px-2 rounded transition flex gap-2 items-center w-full"
                                    to="/changelog/$id"
                                    params={{
                                      id: changelog.id,
                                    }}
                                  >
                                    {changelog.title}
                                  </Link>

                                  <Button
                                    size={'icon'}
                                    variant={'ghost-destructive'}
                                    onClick={() =>
                                      removeChangelog(changelog.id)
                                    }
                                  >
                                    <CircleMinusIcon className="w-4 h-4" />
                                  </Button>
                                </div>
                              )
                            })}
                          </div>
                        </CardContent>
                      </Card>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </PageWrapper>
    </Layout>
  )
}

export const Route = createFileRoute('/page/$id')({
  component: Component,
})
