import { workspaceGetOptions } from '@boring.tools/hooks'
import { Card, CardContent, CardHeader, CardTitle } from '@boring.tools/ui'
import { useSuspenseQuery } from '@tanstack/react-query'
import { createFileRoute, redirect } from '@tanstack/react-router'
import { Layout } from '../../components/Layout'
import { PageWrapper } from '../../components/PageWrapper'
import { WorkspaceSettingsDelete } from '../../components/Workspace/Settings/Delete'
import { WorkspaceSettingsGeneral } from '../../components/Workspace/Settings/General'
import { WorkspaceSettingsLogo } from '../../components/Workspace/Settings/Logo'

const Component = () => {
  const { data } = useSuspenseQuery(workspaceGetOptions())

  return (
    <Layout>
      <PageWrapper
        breadcrumbs={[
          {
            name: 'Workspace',
            to: '/workspace',
          },
          {
            name: data.name,
          },
        ]}
      >
        <div className="w-full max-w-screen-md flex flex-col gap-5">
          <h1 className="text-2xl">Workspace settings</h1>
          <p>Manage your workspace settings.</p>

          <div className="flex gap-5 w-full flex-col md:flex-row">
            <Card className="w-full">
              <CardHeader>
                <CardTitle>General</CardTitle>
              </CardHeader>
              <CardContent>
                <WorkspaceSettingsGeneral data={data} />
              </CardContent>
            </Card>
            <Card>
              <CardHeader>
                <CardTitle>Logo</CardTitle>
              </CardHeader>
              <CardContent>
                <WorkspaceSettingsLogo data={data} />
              </CardContent>
            </Card>
          </div>

          <WorkspaceSettingsDelete />
        </div>
      </PageWrapper>
    </Layout>
  )
}

export const Route = createFileRoute('/workspace/')({
  component: Component,
  beforeLoad: ({ context, location }) => {
    if (!context.auth.user) {
      throw redirect({
        to: '/authentication',
        search: {
          redirect: location.href,
        },
      })
    }
  },
  loader: ({ context }) =>
    context.queryClient?.ensureQueryData(workspaceGetOptions()),
})
