import {
  changelogByIdOptions,
  changelogVersionByIdOptions,
  useChangelogCommitList,
  useChangelogVersionUpdate,
} from '@boring.tools/hooks'
import {
  type VersionCreateInput,
  VersionUpdateInput,
} from '@boring.tools/schema'
import {
  Button,
  Checkbox,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  MinimalTiptapEditor,
  ScrollArea,
  Separator,
  Tabs,
  TabsContent,
} from '@boring.tools/ui'
import type { z } from '@hono/zod-openapi'
import { zodResolver } from '@hookform/resolvers/zod'
import { useSuspenseQueries } from '@tanstack/react-query'
import { createFileRoute, redirect, useNavigate } from '@tanstack/react-router'
import { format } from 'date-fns'
import { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { ChangelogVersionFormDetails } from '../../../../components/Changelog/Version/Form/Details'
import { ChangelogVersionFormRelease } from '../../../../components/Changelog/Version/Form/Release'
import { ChangelogVersionDelete } from '../../../../components/Changelog/VersionDelete'
import { Layout } from '../../../../components/Layout'
import { PageWrapper } from '../../../../components/PageWrapper'

const Component = () => {
  const [activeTab, setActiveTab] = useState('assigned')
  const { id, versionId } = Route.useParams()
  const navigate = useNavigate()
  const versionUpdate = useChangelogVersionUpdate()
  const [changelog, version] = useSuspenseQueries({
    queries: [changelogByIdOptions(id), changelogVersionByIdOptions(versionId)],
  })
  const commitResult = useChangelogCommitList({ id })
  const ref = useRef<HTMLFormElement>(null)

  const form = useForm<z.infer<typeof VersionCreateInput>>({
    resolver: zodResolver(VersionUpdateInput),
    defaultValues: version.data,
  })

  const onSubmit = (values: z.infer<typeof VersionUpdateInput>) => {
    versionUpdate.mutate(
      { id: versionId, payload: values },
      {
        onSuccess() {
          navigate({ to: '/changelog/$id', params: { id } })
        },
      },
    )
  }

  useEffect(() => {
    if (version.data) {
      form.reset({
        ...version.data,
        commitIds: version.data.commits?.map((commit) => commit.id),
      })
    }
  }, [version.data, form])

  return (
    <Layout>
      <PageWrapper
        className="p-0 lg:p-0"
        breadcrumbs={[
          { name: 'Changelog', to: '/changelog' },
          {
            name: changelog.data?.title,
            to: `/changelog/${changelog.data?.id}`,
          },
          {
            name: version.data?.version,
            to: `/changelog/${changelog.data?.id}/version/${version.data?.id}`,
          },
        ]}
      >
        {version.data ? (
          <Form {...form}>
            <form
              onSubmit={form.handleSubmit(onSubmit)}
              className="w-full flex h-full"
              ref={ref}
            >
              <div className="w-full max-w-80 min-w-56 border-r border-muted p-5 h-full justify-between flex flex-col">
                <div className="flex flex-col gap-5">
                  <ChangelogVersionFormDetails form={form} />
                  {/* <ChangelogVersionFormMeta form={form} version={version.data} /> */}
                  <ChangelogVersionFormRelease form={form} />
                </div>

                <div className="flex flex-col gap-5">
                  <div className="flex gap-5 mt-5 w-full justify-end items-end md:col-span-6">
                    <Button
                      type="button"
                      variant={'ghost'}
                      onClick={() =>
                        navigate({ to: '/changelog/$id', params: { id } })
                      }
                    >
                      Cancel
                    </Button>
                    <Button type="submit">Save</Button>
                  </div>

                  <Separator />

                  <ChangelogVersionDelete id={id} versionId={versionId} />
                </div>
              </div>

              <div className="w-full">
                <h2 className="text-lg font-bold mb-2 p-5">Release Notes</h2>
                <Separator />
                <FormField
                  control={form.control}
                  name="content"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className="sr-only">Changes</FormLabel>
                      <FormControl>
                        <MinimalTiptapEditor
                          value={field.value}
                          onChange={field.onChange}
                          className="w-full border-none"
                          editorContentClassName="p-5 w-full"
                          output="html"
                          placeholder="Type your release notes here..."
                          editable={true}
                          editorClassName="focus:outline-none"
                          height={ref.current?.clientHeight}
                        />
                      </FormControl>{' '}
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>

              <div className="w-full max-w-80 min-w-56 border-l border-muted p-5 h-full">
                <h2 className="text-lg font-bold mb-2">Commits</h2>
                <div className="flex gap-2">
                  <Button
                    type="button"
                    variant={activeTab === 'assigned' ? 'outline' : 'ghost'}
                    size={'sm'}
                    onClick={() => setActiveTab('assigned')}
                  >
                    Assigend
                  </Button>
                  <Button
                    type="button"
                    variant={activeTab === 'unassigned' ? 'outline' : 'ghost'}
                    size={'sm'}
                    onClick={() => setActiveTab('unassigned')}
                  >
                    Unassigend
                  </Button>
                </div>
                <Tabs value={activeTab} className="w-full">
                  <TabsContent value="assigned">
                    <ScrollArea
                      className="w-full h-[580px]"
                      style={{
                        height: (ref.current?.clientHeight ?? 800) - 130,
                      }}
                    >
                      <div className="flex flex-col gap-2">
                        {version.data?.commits?.length === 0 && (
                          <div>No commits found</div>
                        )}
                        {version.data?.commits?.map((commit) => {
                          return (
                            <FormField
                              key={commit.id}
                              control={form.control}
                              name={'commitIds'}
                              render={({ field }) => {
                                return (
                                  <FormItem className="flex flex-row items-start space-x-3 space-y-0 rounded-md ">
                                    <FormControl>
                                      <Checkbox
                                        value={commit.id}
                                        checked={field.value?.includes(
                                          commit.id,
                                        )}
                                        onCheckedChange={() => {
                                          const exist = field.value?.includes(
                                            commit.id,
                                          )
                                          if (exist) {
                                            return field.onChange(
                                              field.value?.filter(
                                                (value) => value !== commit.id,
                                              ),
                                            )
                                          }
                                          return field.onChange([
                                            ...(field.value as string[]),
                                            commit.id,
                                          ])
                                        }}
                                      />
                                    </FormControl>
                                    <div className="space-y-1 leading-none w-full">
                                      <FormLabel className="flex gap-2 w-full">
                                        <span className="text-muted-foreground font-mono">
                                          {commit.commit}{' '}
                                        </span>
                                        <span className="w-full">
                                          {commit.description}
                                        </span>
                                        <span>
                                          {format(
                                            new Date(commit.author.date),
                                            'dd.MM.',
                                          )}
                                        </span>
                                      </FormLabel>
                                    </div>
                                  </FormItem>
                                )
                              }}
                            />
                          )
                        })}
                      </div>
                    </ScrollArea>
                  </TabsContent>
                  <TabsContent value="unassigned">
                    <ScrollArea className="w-full h-[350px]">
                      <div className="flex flex-col gap-2">
                        {commitResult?.data?.length === 0 && (
                          <div>No commits found</div>
                        )}
                        {commitResult.data?.map((commit) => {
                          return (
                            <FormField
                              key={commit.id}
                              control={form.control}
                              name={'commitIds'}
                              render={({ field }) => {
                                return (
                                  <FormItem className="flex flex-row items-start space-x-3 space-y-0 rounded-md ">
                                    <FormControl>
                                      <Checkbox
                                        value={commit.id}
                                        checked={field.value?.includes(
                                          commit.id,
                                        )}
                                        onCheckedChange={() => {
                                          const exist = field.value?.includes(
                                            commit.id,
                                          )
                                          if (exist) {
                                            return field.onChange(
                                              field.value?.filter(
                                                (value: string) =>
                                                  value !== commit.id,
                                              ),
                                            )
                                          }
                                          return field.onChange([
                                            ...(field.value as string[]),
                                            commit.id,
                                          ])
                                        }}
                                      />
                                    </FormControl>
                                    <div className="space-y-1 leading-none w-full">
                                      <FormLabel className="flex gap-2 w-full">
                                        <span className="text-muted-foreground font-mono">
                                          {commit.commit}
                                        </span>
                                        <span className="w-full">
                                          {commit.description}
                                        </span>
                                        <span>
                                          {format(
                                            new Date(commit.author.date),
                                            'dd.MM.',
                                          )}
                                        </span>
                                      </FormLabel>
                                    </div>
                                  </FormItem>
                                )
                              }}
                            />
                          )
                        })}
                      </div>
                    </ScrollArea>
                  </TabsContent>
                </Tabs>
              </div>
            </form>
          </Form>
        ) : null}
      </PageWrapper>
    </Layout>
  )
}

export const Route = createFileRoute('/changelog_/$id/version_/$versionId')({
  component: Component,
  beforeLoad: ({ context, location }) => {
    if (!context.auth.user) {
      throw redirect({
        to: '/authentication',
        search: {
          redirect: location.href,
        },
      })
    }
  },
  loader: ({ context, params }) => {
    context.queryClient?.ensureQueryData(changelogByIdOptions(params.id))
    return context.queryClient?.ensureQueryData(
      changelogVersionByIdOptions(params.versionId),
    )
  },
})
