import { useAuthenticationEmailCallback } from '@boring.tools/hooks'
import { createFileRoute, useSearch } from '@tanstack/react-router'
import { LoaderCircleIcon } from 'lucide-react'
import { useTimeout } from 'usehooks-ts'
import { z } from 'zod'

const Component = () => {
  const emailCallback = useAuthenticationEmailCallback()
  const { email, token } = useSearch({
    from: '/authentication/provider/email/callback/',
  })

  const signin = async () => {
    if (token) {
      emailCallback.mutate(
        { token, email },
        {
          onSettled() {
            window.location.replace('/')
          },
        },
      )
    }
  }

  useTimeout(signin, 1500)

  if (emailCallback.error) {
    return <div>{emailCallback.error.message}</div>
  }

  return (
    <div className="w-full h-screen flex items-center justify-center flex-col">
      <h1 className="text-2xl">boring.tools</h1>
      <p>Signing you in with your E-Mail</p>

      <div className="mt-4">
        <LoaderCircleIcon size={32} strokeWidth={1} className="animate-spin" />
      </div>
    </div>
  )
}

const schema = z.object({
  email: z.string().email(),
  token: z.string(),
})

export const Route = createFileRoute(
  '/authentication/provider/email/callback/',
)({
  component: Component,
  validateSearch: schema,
  errorComponent: () => <div>Required Parameters missing</div>,
})
