import {
  type AuthContext,
  changelogListOptions,
  userQueryOptions,
  workspaceGetAllOptions,
} from '@boring.tools/hooks'
import type { QueryClient } from '@tanstack/react-query'
import { createRootRouteWithContext, useLocation } from '@tanstack/react-router'
import { LayoutAuthenticated } from '../components/Layout/Authenticated'
import { LayoutPublic } from '../components/Layout/Public'

interface RouterContext {
  auth: AuthContext
  queryClient: QueryClient | null
}

const publicRoutes = [
  '/authentication',
  '/authentication/provider/github',
  '/authentication/provider/github/callback',
  '/workspace/invite',
]

export const Route = createRootRouteWithContext<RouterContext>()({
  component: () => {
    const location = useLocation()
    if (publicRoutes.includes(location.pathname)) {
      return <LayoutPublic />
    }

    return <LayoutAuthenticated />
  },
  loader({ context }) {
    context.queryClient?.ensureQueryData(changelogListOptions())
    context.queryClient?.ensureQueryData(userQueryOptions)
    context.queryClient?.ensureQueryData(workspaceGetAllOptions())
  },

  errorComponent: () => <div>404</div>,
})
