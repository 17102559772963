import {
  useWorkspaceMemberDelete,
  useWorkspaceMemberInviteDelete,
  workspaceMemberInvitesGetOptions,
} from '@boring.tools/hooks'
import type { WorkspaceOutput } from '@boring.tools/schema'
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
  Button,
  CardTitle,
  Separator,
  toast,
} from '@boring.tools/ui'
import type { z } from '@hono/zod-openapi'
import { useQuery } from '@tanstack/react-query'
import { TrashIcon } from 'lucide-react'

export const WorkspaceSettingsMembers = ({
  data,
}: { data: z.infer<typeof WorkspaceOutput> }) => {
  const memberInvites = useQuery(workspaceMemberInvitesGetOptions)
  const deleteMember = useWorkspaceMemberDelete()
  const deleteInvite = useWorkspaceMemberInviteDelete()

  const handleDelete = (id: string) => {
    deleteMember.mutate(id, {
      onSuccess: () => {
        toast.success('Member removed')
      },
      onError: (error) => {
        toast.error(error.message)
      },
    })
  }

  const handleInviteDelete = (email: string) => {
    deleteInvite.mutate(email, {
      onSuccess: () => {
        toast.success('Invite removed')
      },
      onError: (error) => {
        toast.error(error.message)
      },
    })
  }

  return (
    <div>
      <div className="flex flex-col gap-5">
        {data.members?.map((member) => {
          return (
            <div key={member.id} className="flex items-center justify-between">
              <div className="flex gap-3 items-center">
                <Avatar className="w-7 h-7">
                  <AvatarImage src={member.image ?? ''} alt={member.name} />
                  <AvatarFallback className="rounded-lg">
                    {member?.name?.substring(0, 2)}
                  </AvatarFallback>
                </Avatar>
                {member.name} - {member.email}
              </div>
              <div>
                <Button
                  type="button"
                  variant={'ghost-destructive'}
                  size={'sm'}
                  onClick={() => handleDelete(member.memberId)}
                >
                  <TrashIcon className="w-4 h-4" strokeWidth={1.5} />
                </Button>
              </div>
            </div>
          )
        })}
      </div>

      {memberInvites.data && memberInvites.data?.length > 0 && (
        <div>
          <Separator className="mt-3 mb-3" />
          <CardTitle>Invites</CardTitle>
          <div className="flex flex-col gap-3 mt-4">
            {memberInvites.data.map((invite) => {
              return (
                <div
                  key={invite.email}
                  className="flex items-center justify-between"
                >
                  <div>{invite.email}</div>
                  <Button
                    type="button"
                    variant={'ghost-destructive'}
                    size={'sm'}
                    onClick={() => handleInviteDelete(invite.email)}
                  >
                    <TrashIcon className="w-4 h-4" strokeWidth={1.5} />
                  </Button>
                </div>
              )
            })}
          </div>
        </div>
      )}
    </div>
  )
}
