import { createFileRoute, redirect } from '@tanstack/react-router'

import {
  pageByIdOptions,
  useChangelogList,
  usePageById,
  usePageUpdate,
} from '@boring.tools/hooks'
import { PageUpdateInput } from '@boring.tools/schema'
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Textarea,
  cn,
} from '@boring.tools/ui'
import type { z } from '@hono/zod-openapi'
import { zodResolver } from '@hookform/resolvers/zod'
import { useNavigate } from '@tanstack/react-router'
import { Check, ChevronsUpDown } from 'lucide-react'
import { useForm } from 'react-hook-form'
import { Layout } from '../../../components/Layout'
import { PageSettingsLogo } from '../../../components/Page/Logo'
import { PageWrapper } from '../../../components/PageWrapper'

const Component = () => {
  const { id } = Route.useParams()
  const navigate = useNavigate()
  const page = usePageById({ id })
  const changelogList = useChangelogList()
  const pageUpdate = usePageUpdate()
  const form = useForm<z.infer<typeof PageUpdateInput>>({
    resolver: zodResolver(PageUpdateInput),
    defaultValues: {
      ...page.data,
      changelogIds: page.data?.changelogs?.map((log) => log.id),
    },
  })
  const onSubmit = (values: z.infer<typeof PageUpdateInput>) => {
    pageUpdate.mutate(
      { id, payload: values },
      {
        onSuccess(data) {
          navigate({ to: '/page/$id', params: { id: data.id } })
        },
      },
    )
  }

  return (
    <Layout>
      <PageWrapper
        breadcrumbs={[
          {
            name: 'Page',
            to: '/page',
          },
          { name: page.data?.name ?? '', to: `/page/${page.data?.id}` },
        ]}
      >
        <div className="flex flex-col gap-5">
          <Form {...form}>
            <form
              onSubmit={form.handleSubmit(onSubmit)}
              className="flex flex-col gap-10 w-full max-w-screen-2xl"
            >
              <div className="flex gap-10 w-full">
                <Card className="w-full">
                  <CardHeader>
                    <CardTitle>Details</CardTitle>
                  </CardHeader>
                  <CardContent className="flex flex-col gap-5">
                    <FormField
                      control={form.control}
                      name="name"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Name</FormLabel>
                          <FormControl>
                            <Input placeholder="My page" {...field} autoFocus />
                          </FormControl>{' '}
                          <FormMessage />
                        </FormItem>
                      )}
                    />

                    <FormField
                      control={form.control}
                      name="description"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Description</FormLabel>
                          <FormControl>
                            <Textarea
                              placeholder="Some details about the page..."
                              {...field}
                              value={field.value ?? ''}
                            />
                          </FormControl>{' '}
                          <FormMessage />
                        </FormItem>
                      )}
                    />

                    <FormField
                      control={form.control}
                      name="url"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>CNAME URL</FormLabel>
                          <FormControl>
                            <Input
                              placeholder="status.yourdomain.com"
                              {...field}
                              value={field.value ?? ''}
                            />
                          </FormControl>{' '}
                          <FormDescription>
                            Set your CNAME record to <i>page.boring.tools</i>
                          </FormDescription>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </CardContent>
                </Card>

                <Card className="w-full">
                  <CardHeader>
                    <CardTitle>Options</CardTitle>
                  </CardHeader>
                  <CardContent className="flex flex-col gap-5">
                    <FormField
                      control={form.control}
                      name="changelogIds"
                      render={({ field }) => (
                        <FormItem className="flex flex-col">
                          <FormLabel>Changelogs</FormLabel>
                          <Popover>
                            <PopoverTrigger asChild>
                              <FormControl>
                                <Button
                                  variant="outline"
                                  // biome-ignore lint/a11y/useSemanticElements: <explanation>
                                  role="combobox"
                                  className={cn(
                                    'w-[200px] justify-between',
                                    !field.value && 'text-muted-foreground',
                                  )}
                                >
                                  {field?.value?.length === 1 &&
                                    changelogList.data?.find((changelog) =>
                                      field.value?.includes(changelog.id),
                                    )?.title}
                                  {field?.value &&
                                    field.value.length <= 0 &&
                                    'No changelog selected'}
                                  {field?.value &&
                                    field.value.length > 1 &&
                                    `${field?.value?.length} selected`}
                                  <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                                </Button>
                              </FormControl>
                            </PopoverTrigger>
                            <PopoverContent className="w-[200px] p-0">
                              <Command>
                                <CommandInput placeholder="Search changelogs..." />
                                <CommandList>
                                  <CommandEmpty>
                                    No changelog found.
                                  </CommandEmpty>
                                  <CommandGroup>
                                    {changelogList.data?.map((changelog) => (
                                      <CommandItem
                                        value={changelog.title}
                                        key={changelog.id}
                                        onSelect={() => {
                                          const getIds = () => {
                                            if (!field.value) {
                                              return [changelog.id]
                                            }

                                            if (
                                              field.value?.includes(
                                                changelog.id,
                                              )
                                            ) {
                                              return field.value.filter(
                                                (id) => id !== changelog.id,
                                              )
                                            }

                                            return [
                                              ...(field?.value as string[]),
                                              changelog.id,
                                            ]
                                          }
                                          form.setValue(
                                            'changelogIds',
                                            getIds(),
                                          )
                                        }}
                                      >
                                        <Check
                                          className={cn(
                                            'mr-2 h-4 w-4',
                                            field.value?.includes(changelog.id)
                                              ? 'opacity-100'
                                              : 'opacity-0',
                                          )}
                                        />
                                        {changelog.title}
                                      </CommandItem>
                                    ))}
                                  </CommandGroup>
                                </CommandList>
                              </Command>
                            </PopoverContent>
                          </Popover>
                          <FormDescription>
                            This changelogs are shown on this page.
                          </FormDescription>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </CardContent>
                </Card>
                <Card className="w-full">
                  <CardHeader>
                    <CardTitle>Logo</CardTitle>
                  </CardHeader>
                  <CardContent className="flex flex-col gap-5">
                    {page?.data && <PageSettingsLogo data={page.data} />}
                  </CardContent>
                </Card>
              </div>

              <div className="flex items-end justify-end gap-5">
                <Button
                  type="button"
                  variant={'ghost'}
                  onClick={() => navigate({ to: '/page/$id', params: { id } })}
                >
                  Cancel
                </Button>
                <Button type="submit">Save</Button>
              </div>
            </form>
          </Form>
        </div>
      </PageWrapper>
    </Layout>
  )
}

export const Route = createFileRoute('/page_/$id/edit')({
  component: Component,
  beforeLoad: ({ context, location }) => {
    if (!context.auth.user) {
      throw redirect({
        to: '/authentication',
        search: {
          redirect: location.href,
        },
      })
    }
  },
  loader: ({ context, params }) => {
    return context.queryClient?.ensureQueryData(pageByIdOptions(params.id))
  },
})
