import type { FileOutput } from '@boring.tools/schema'
import type { z } from '@hono/zod-openapi'
import {
  queryOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'
import { queryFetch } from '../lib/queryFetch'

type FileList = z.infer<typeof FileOutput>

export const fileListOptions = () =>
  queryOptions({
    queryKey: ['fileList'],
    queryFn: async (): Promise<FileList[]> =>
      await queryFetch({
        path: 'file/list',
        method: 'get',
      }),
  })

export const useFileDelete = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ id }: { id: string }): Promise<Readonly<FileList>> =>
      await queryFetch({
        path: `file/${id}`,
        method: 'delete',
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['fileList'],
      })
    },
  })
}

export const useFileUpload = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (file: File): Promise<void> => {
      const data = new FormData()
      data.append('file', file)

      return await queryFetch({
        method: 'post',
        path: 'file',
        data,
      })
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['fileList'],
      })
    },
  })
}
