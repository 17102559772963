import { changelogCommitStatistics } from '@boring.tools/hooks'
import type { CommitStatisticsOutput } from '@boring.tools/schema'
import {
  Bar,
  BarChart,
  Button,
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  type ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
  PolarAngleAxis,
  PolarGrid,
  Radar,
  RadarChart,
  ReferenceLine,
  XAxis,
} from '@boring.tools/ui'
import type { z } from '@hono/zod-openapi'
import { useQuery } from '@tanstack/react-query'
import { format, subDays } from 'date-fns'
import { flattenDeep, groupBy } from 'lodash'
import { useEffect, useState } from 'react'

const chartConfig = {
  commits: {
    label: 'Commits',
    color: '#0d9488',
  },
  count: {
    label: 'Commits',
    color: '#0d9488',
  },
} satisfies ChartConfig

export const DashboardCharts = ({ changelogId }: { changelogId?: string }) => {
  const [days, setDays] = useState(14)

  const getRange = (days = 30) => {
    return { from: subDays(new Date(), days), to: new Date(), changelogId }
  }
  const { data, refetch } = useQuery(changelogCommitStatistics(getRange(days)))

  const types = flattenDeep(
    data?.map((d) => d.types) as unknown as { type: string; count: number }[],
  ).filter(Boolean)
  const groupedTypes = groupBy(types, 'type')
  const sumTypes = Object.entries(groupedTypes).map(([type, values]) => ({
    type,
    count: values.reduce((acc, curr) => acc + curr.count, 0),
  }))

  const scopes = flattenDeep(
    data?.map((d) => d.scopes) as unknown as { scope: string; count: number }[],
  ).filter(Boolean)
  const groupedScopes = groupBy(scopes, 'scope')
  const sumScopes = Object.entries(groupedScopes).map(([scope, values]) => ({
    scope,
    count: values.reduce((acc, curr) => acc + curr.count, 0),
  }))

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    refetch()
  }, [days])

  return (
    <div className="grid grid-cols-6 gap-5 w-full max-w-screen-2xl">
      <div className="col-span-6 flex justify-between">
        <div>
          {format(getRange(days).from, 'dd.MM.')}
          {' - '}
          {format(getRange(days).to, 'dd.MM.yy')}
        </div>
        <div className="flex gap-2">
          <Button
            size={'xs'}
            onClick={() => setDays(14)}
            type="button"
            variant={days === 14 ? 'default' : 'ghost'}
          >
            14 Days
          </Button>

          <Button
            size={'xs'}
            onClick={() => setDays(30)}
            type="button"
            variant={days === 30 ? 'default' : 'ghost'}
          >
            30 Days
          </Button>
        </div>
      </div>
      <div className="col-span-6" />
      <Card className="col-span-6 max-h-56">
        <CardHeader className="flex justify-between items-center flex-row">
          <div>
            <CardTitle>Commits per day</CardTitle>
          </div>
        </CardHeader>
        <CardContent>
          {data && data?.length > 0 && (
            <ChartContainer config={chartConfig} className="h-32 w-full">
              <BarChart
                accessibilityLayer
                data={data as z.infer<typeof CommitStatisticsOutput>}
              >
                <XAxis
                  dataKey="date"
                  tickLine={false}
                  tickMargin={10}
                  axisLine={false}
                  tickFormatter={(value) => format(new Date(value), 'dd.MM.')}
                />
                {data?.map((item) => {
                  if (item.hasRelease) {
                    return (
                      <ReferenceLine
                        key={item.date as string}
                        x={item.date as string}
                        stroke="#67e8f9"
                        color="#0284c7"
                        isFront={true}
                        strokeWidth={2}
                        // label="Release"
                      />
                    )
                  }
                  return null
                })}
                <Bar
                  dataKey="commits"
                  fill="var(--color-commits)"
                  radius={4}
                  opacity={0.7}
                />
                <ChartTooltip
                  labelFormatter={(value) =>
                    format(new Date(value), 'dd.MM.yyyy')
                  }
                  content={
                    <ChartTooltipContent
                      labelFormatter={(value) => {
                        return value
                      }}
                    />
                  }
                  cursor={false}
                  defaultIndex={1}
                />
              </BarChart>
            </ChartContainer>
          )}
        </CardContent>
      </Card>

      <div className="col-span-6" />

      <Card className="col-span-3 max-h-72">
        <CardHeader className="flex justify-between items-center flex-row">
          <CardTitle>Types</CardTitle>
        </CardHeader>
        <CardContent>
          {data && data?.length > 0 && (
            <ChartContainer
              config={chartConfig}
              className="mx-auto aspect-square max-h-[200px]"
            >
              <RadarChart data={sumTypes}>
                <ChartTooltip
                  cursor={false}
                  content={<ChartTooltipContent />}
                />
                <PolarAngleAxis dataKey="type" />
                <PolarGrid />
                <Radar
                  dataKey="count"
                  fill="var(--color-count)"
                  fillOpacity={0.6}
                />
              </RadarChart>
            </ChartContainer>
          )}
        </CardContent>
      </Card>

      <Card className="col-span-3 max-h-72">
        <CardHeader className="flex justify-between items-center flex-row">
          <CardTitle>Scopes</CardTitle>
        </CardHeader>
        <CardContent>
          {data && data?.length > 0 && (
            <ChartContainer
              config={chartConfig}
              className="mx-auto aspect-square max-h-[200px]"
            >
              <RadarChart data={sumScopes}>
                <ChartTooltip
                  cursor={false}
                  content={<ChartTooltipContent />}
                />
                <PolarAngleAxis dataKey="scope" fontSize={10} />
                <PolarGrid />
                <Radar
                  dataKey="count"
                  fill="var(--color-count)"
                  fillOpacity={0.6}
                />
              </RadarChart>
            </ChartContainer>
          )}
        </CardContent>
      </Card>
    </div>
  )
}
